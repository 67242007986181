import React from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import { bemBlock } from '../../../modules/bem';
import { LayoutRow } from '../../layout-row/LayoutRow';
import { Text } from '../../text/Text';

import { ItemOwnProps } from './item-props';

import './ActionsMenuItem.less';

const block = bemBlock('n-actions-menu-item');

type ItemProps = React.ComponentPropsWithoutRef<typeof DropdownMenu.Item> & ItemOwnProps;

export const ActionsMenuItem = React.forwardRef<
  React.ElementRef<typeof DropdownMenu.Item>,
  ItemProps
>(({ className, children, leadingIcon, trailingElement, active, destructive, ...props }, ref) => {
  return (
    <DropdownMenu.Item
      ref={ref}
      className={block({
        extra: className,
        modifiers: { active, destructive },
      })}
      {...props}
    >
      <LayoutRow span="auto" alignItems="center" spacedChildren="sm" width="100%">
        {leadingIcon}
        <Text size="sm">{children}</Text>
      </LayoutRow>
      {trailingElement}
    </DropdownMenu.Item>
  );
});
