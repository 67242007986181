import { RE2JS } from 're2js';

export const prepareRegexpSearchWithAnchors = (input: string): string => {
  const userInput = input.trim();

  if (!userInput) {
    return '.*';
  }

  return `.*(${userInput}).*`
    .replace(/^...\^/, '(') // handles user provided ^ at the beginning
    .replace(/\$...$/, ')'); // handles user provided $ at the end
};

export const convertRegexpSearchToFilter = (search?: string, exclude?: string) => {
  return search || exclude
    ? {
        mustMatchRegexes: search ? [search] : undefined,
        mustNotMatchRegexes: exclude ? [exclude] : undefined,
      }
    : undefined;
};

export const isValidRegExp = (regexp: string) => {
  try {
    new RegExp(regexp);
  } catch {
    return false;
  }

  return true;
};

// TODO: This is the function we should be using for all the regexp. To remove
// after merging https://gitlab.com/neptune.ml/neptune/-/merge_requests/14747
export const isValidRE2RegExp = (regexp: string) => {
  try {
    RE2JS.compile(regexp);
    return true;
  } catch {
    return false;
  }
};
