import React from 'react';

import { useInViewport } from './useInViewport';
import { ViewportContext } from './ViewportContext';

/**
 * Triggers callbacks when component will be visible or not on the screen viewport
 */
export const InViewport: React.FC<{
  viewport: ViewportContext;
  onShow?: () => unknown;
  onHide?: () => unknown;
}> = ({ viewport, onShow, onHide, children }) => {
  const [domContainer, setDomContainer] = React.useState<HTMLElement | null>(null);

  useInViewport({
    viewport,
    onShow,
    onHide,
    domContainer,
  });

  return (
    <div
      style={{ minWidth: 16, minHeight: 16 }}
      ref={(instance) => setDomContainer(instance)}
      children={children}
    />
  );
};
