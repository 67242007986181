'use strict';

const version = process.env.VERSION;

const neptuneApiBasePath = 'localhost';
const neptuneApiSecure = window.location.protocol === 'https:';
const neptuneApiPort = '8000';
const neptuneCookieDomain = 'neptune.ai';

const blogURL = 'https://neptune.ai/blog';
const documentationURL = 'https://docs-beta.neptune.ai';
const onboardingLandingPageURL = documentationURL;
const integrationQuickStartURL = documentationURL;
const onpremInternalDocsURL = documentationURL;
const wwwUrl = 'https://neptune.ai';
const roadmapUrl = 'https://portal.neptune.ai/tabs/15-planned';
const onPremReleaseNotesUrl = 'https://onprem.neptune.ai/';
const contactEmail = 'contact@neptune.ai';
const helloWorldCollabUrl =
  'https://colab.research.google.com/github/neptune-ai/examples/blob/master/how-to-guides/hello-world/notebooks/Neptune_hello_world.ipynb';
const pricingFaqURL = 'https://neptune.ai/pricing#faq';
const termsOfServiceUrl = 'https://neptune.ai/terms-of-service';
const privacyPolicyUrl = 'https://neptune.ai/privacy-policy';
const pricingURL = 'https://neptune.ai/pricing';

const logoutURL = '/';

const allowAddingNewLicense = false;

// we assume that port of Websockets is always the same as rest API port,
// if this will change then it should be delivered as part of docker config
const neptuneApiWSPort = neptuneApiPort;

const keycloakURL = '/auth';
const keycloakRealm = 'neptune';
const keycloakFrontendClientId = 'neptune-frontend';

// this is test public key only for development
// production key is delivered via Docker Env
const stripePublicKey = 'pk_test_Ceybqs6yTccRiDGpiVDzEw5w';

// Deployment mode can be one of 'classic' or 'on-premises'.
// Using this flag we can control how app behaves depending on type of the deployment
const neptuneDeploymentMode = window.dockerConfig?.neptuneDeploymentMode ?? 'classic';

const sorryAppId = '';
const sorryAppPollingTimeMs = 10 * 1000;
const sorryAppMaintenanceSubject = 'Maintenance break';

// Using this flag we can control object-table suggestions. When it's false, suggestions are not accessible in the UI
const suggestionsEnabled = true;

// It's default setting for visibility of parallel plot, it can be configured in docker config
const parallelPlotEnabled = true;

// It's default setting for condensed mode, it can be configured in docker config
const condensedModeEnabled = false;

/* Default setting for name column for runs leaderboard, it can be configured in docker config. Make sure it is
 * included in defaultVisibleObjectTableFields and defaultPinnedObjectTableFields */
const primaryColumn = 'id';

/* Default visibility of object-table fields, it can be configured in docker config. Make sure it includes primaryColumn */
const defaultVisibleObjectTableFields = `sys/${primaryColumn},sys/creation_time,sys/owner,sys/tags,sys/group_tags`;

/* Default pinned status of object-table fields, it can be configured in docker config. Make sure it includes primaryColumn */
const defaultPinnedObjectTableFields = `sys/${primaryColumn},sys/stage,sys/creation_time`;

const withNewEmptyState = true;
const hideRunSize = true;
const hideMonitoringTime = true;
const disableRemoteRunActions = true;
const disableNotSupportedWidgets = true;
const withIngestApi = true;
const withDisabledTrashing = true;

const limits = {
  fileSetPreviewFilesLimit: 1000,
  fileSetDownloadLimit: 1000,
  objectTableObjectsLimit: 10000,
  maxChartsWidgetsDisplayed: 1000,
  maxImagesWidgetsDisplayed: 1000,
  versionsDropdownPageLimit: 100,
  chartMaxMetricsLimit: 1000,
};

// Settings configured here will be merged into the final config object.
export default {
  isProduction: false,
  neptuneDeploymentMode,
  stripePublicKey,
  neptuneCookieDomain,
  contactEmail,
  blogURL,
  helloWorldCollabUrl,
  sorryAppId,
  sorryAppPollingTimeMs,
  sorryAppMaintenanceSubject,
  documentationURL,
  onboardingLandingPageURL,
  integrationQuickStartURL,
  onpremInternalDocsURL,
  wwwUrl,
  pricingFaqURL,
  pricingURL,
  roadmapUrl,
  onPremReleaseNotesUrl,
  logoutURL,
  allowAddingNewLicense,
  keycloakURL,
  keycloakRealm,
  keycloakFrontendClientId,
  neptuneApiBasePath,
  neptuneApiPort,
  neptuneApiWSPort,
  neptuneApiSecure,
  version,
  termsOfServiceUrl,
  privacyPolicyUrl,
  suggestionsEnabled,
  parallelPlotEnabled,
  condensedModeEnabled,
  defaultVisibleObjectTableFields,
  defaultPinnedObjectTableFields,
  primaryColumn,
  withNewEmptyState,
  hideRunSize,
  hideMonitoringTime,
  disableRemoteRunActions,
  disableNotSupportedWidgets,
  withIngestApi,
  withDisabledTrashing,
  ...limits,
};
