import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';

import type { GlyphName } from '@neptune/shared/core-glyphs-domain';

import { CustomIconProp } from './types';
type GlyphDefinition = FontAwesomeIconProps | FontAwesomeIconProps[];

interface CustomFontAwesomeIconProps extends Omit<FontAwesomeIconProps, 'icon'> {
  icon: IconProp | CustomIconProp;
}

type CustomGlyphDefinition = CustomFontAwesomeIconProps | CustomFontAwesomeIconProps[];

export type { GlyphName }; // re-export for backward compatibility within so-called core-ui-2

type Glyphs = Record<GlyphName, CustomGlyphDefinition>;

const GLYPHS: Glyphs = {
  ban: { icon: 'ban' },
  cancel: { icon: ['nep', 'cancel'] },
  'catalog-check': { icon: ['nep', 'catalog-check'] },
  cross: { icon: ['nep', 'cross'] },
  'crossed-circle': { icon: ['nep', 'crossed-circle'] },
  dot: { icon: ['nep', 'dot'] },
  'dots-v': { icon: ['nep', 'dots-v'] },
  dropdown: { icon: ['nep', 'dropdown'] },
  edit: { icon: ['nep', 'edit'] },
  'edit-text': { icon: ['nep', 'edit-text'] },
  file: { icon: ['nep', 'file'] },
  filter: { icon: ['nep', 'filter'] },
  'folder-alt': { icon: ['nep', 'folder-alt'] },
  gauge: { icon: ['nep', 'gauge'] },
  hide: { icon: ['nep', 'hide'] },
  'hide-sidebar-left': { icon: ['nep', 'hide-sidebar-left'] },
  home: { icon: ['nep', 'home'] },
  key: { icon: ['nep', 'key'] },
  'left-open': { icon: ['nep', 'left-open'] },
  neptune: { icon: ['nep', 'neptune'] },
  notebook: { icon: ['nep', 'notebook'] },
  ok: { icon: ['nep', 'ok'] },
  'pause-circle': { icon: 'pause-circle' },
  pencil: { icon: ['nep', 'pencil'] },
  pin: { icon: ['nep', 'pin'] },
  report: { icon: ['nep', 'report'] },
  'round-flask': { icon: ['nep', 'round-flask'] },
  share: { icon: ['nep', 'share'] },
  show: { icon: ['nep', 'show'] },
  'show-indeterminate': { icon: ['nep', 'show-indeterminate'] },
  'show-first-five': { icon: ['nep', 'show-first-five'] },
  'show-sidebar-left': { icon: ['nep', 'show-sidebar-left'] },
  'trash-bin': { icon: ['nep', 'trash-bin'] },
  warning: { icon: ['nep', 'warning'] },
  workspace: { icon: ['nep', 'workspace'] },
  abort: [{ icon: ['far', 'circle'] }, { icon: 'square', transform: 'shrink-8' }],
  'ai-model': { icon: ['nep', 'ai-model'] },
  apple: { icon: ['fab', 'apple'] },
  'arrow-up': { icon: 'arrow-up' },
  'arrow-down': { icon: 'arrow-down' },
  'arrow-left': { icon: ['nep', 'arrow-left'] },
  'arrow-right': { icon: ['nep', 'arrow-right'] },
  bars: { icon: ['fas', 'bars'] },
  bell: { icon: ['far', 'bell'] },
  bold: { icon: 'bold' },
  bool: { icon: ['nep', 'bool'] },
  border: { icon: ['far', 'square'] },
  'box-archive': { icon: 'box-archive' },
  briefcase: { icon: 'briefcase' },
  bulb: { icon: ['nep', 'bulb'] },
  calendar: { icon: ['far', 'calendar-alt'] },
  'caret-down': { icon: 'caret-down' },
  'caret-right': { icon: 'caret-right' },
  'caret-square-down': { icon: 'caret-square-down' },
  'caret-square-down-o': { icon: ['far', 'caret-square-down'] },
  'caret-square-right': { icon: 'caret-square-right' },
  'caret-square-right-o': { icon: ['far', 'caret-square-right'] },
  'caret-up': { icon: 'caret-up' },
  carousel: [
    { icon: 'square', transform: 'left-4 shrink-8' },
    { icon: 'square', transform: 'right-4 shrink-8' },
  ],
  'chart-dots': { icon: ['nep', 'chart-dots'] },
  'chart-smooth': { icon: ['nep', 'chart-smooth'] },
  'chart-rough': { icon: ['nep', 'chart-rough'] },
  'chart-line': { icon: 'chart-line' },
  check: { icon: 'check' },
  'check-circle': { icon: 'check-circle' },
  checkmark: { icon: ['nep', 'checkmark'] },
  'chevron-double-left': [
    { icon: 'chevron-left', transform: 'left-3 shrink-4' },
    { icon: 'chevron-left', transform: 'right-3 shrink-4' },
  ],
  'chevron-down': { icon: ['nep', 'chevron-down'] },
  'chevron-double-right': [
    { icon: 'chevron-right', transform: 'left-3 shrink-4' },
    { icon: 'chevron-right', transform: 'right-3 shrink-4' },
  ],
  'chevron-left': { icon: 'chevron-left' },
  'chevron-right': { icon: 'chevron-right' },
  'chevron-up': { icon: ['nep', 'chevron-up'] },
  chip: { icon: ['nep', 'chip'] },
  circle: { icon: ['far', 'circle'] },
  'circle-alt': { icon: ['fas', 'circle'] },
  'circle-cancel': { icon: ['nep', 'circle-cancel'] },
  'circle-notch': { icon: ['fas', 'circle-notch'] },
  'circle-question-mark': { icon: ['nep', 'circle-question-mark'] },
  'circle-arrow-right': { icon: ['nep', 'circle-arrow-right'] },
  clipboard: { icon: ['far', 'clipboard'] },
  clock: { icon: 'clock' },
  clone: { icon: ['far', 'clone'] },
  code: { icon: 'code' },
  cog: { icon: 'cog' },
  'collapse-left-right': { icon: ['nep', 'collapse-left-right'] },
  'collapse-up-down': { icon: ['nep', 'collapse-up-down'] },
  color: { icon: ['nep', 'color'] },
  columns: { icon: 'columns' },
  'columns-configure': { icon: ['nep', 'columns-configure'] },
  'command-square': [
    { icon: 'square' },
    { icon: 'terminal', inverse: true, transform: 'shrink-8' },
  ],
  comment: { icon: ['far', 'comment'] },
  'comment-alt': { icon: ['far', 'comment-alt'] },
  comments: { icon: ['far', 'comments'] },
  compare: { icon: ['nep', 'compare'] },
  compress: { icon: 'compress' },
  'credit-card': { icon: ['far', 'credit-card'] },
  crown: { icon: 'crown' },
  database: { icon: 'database' },
  datetime: { icon: ['nep', 'datetime'] },
  desktop: { icon: 'desktop' },
  'dots-horizontal': { icon: ['nep', 'dots-horizontal'] },
  download: [
    { icon: ['far', 'window-minimize'] },
    { icon: 'arrow-down', transform: 'shrink-2 up-2' },
  ],
  'ellipsis-h': { icon: 'ellipsis-h' },
  'ellipsis-v': { icon: 'ellipsis-v' },
  envelope: { icon: 'envelope' },
  exchange: { icon: 'exchange-alt' },
  exit: { icon: 'sign-out-alt' },
  expand: { icon: 'expand' },
  'external-link': { icon: 'external-link-alt' },
  'exclamation-circle': { icon: 'exclamation-circle' },
  'exclamation-triangle': { icon: 'exclamation-triangle' },
  'eye-star': [{ icon: ['fas', 'eye'] }, { icon: 'star', transform: 'shrink-8 right-7 up-10' }],
  'fast-fast-forward': { icon: ['nep', 'fast-fast-forward'] },
  'fast-forward': { icon: ['nep', 'fast-forward'] },
  'file-alt': { icon: ['far', 'file-alt'] },
  'file-code': { icon: ['far', 'file-code'] },
  'file-export': { icon: 'file-export' },
  'file-import': { icon: 'file-import' },
  'file-series': { icon: ['nep', 'file-series'] },
  'file-set': { icon: ['nep', 'file-set'] },
  float: { icon: ['nep', 'float'] },
  'float-series': { icon: ['nep', 'float-series'] },
  folder: { icon: 'folder' },
  'folder-open': { icon: 'folder-open' },
  font: { icon: 'font' },
  flask: { icon: 'flask' },
  gift: { icon: ['fas', 'gift'] },
  github: { icon: ['nep', 'github'] },
  'globe-americas': { icon: 'globe-americas' },
  'greater-than': { icon: 'greater-than' },
  grid: { icon: ['nep', 'grid'] },
  'grip-bottom-right': { icon: ['nep', 'grip-bottom-right'] },
  'grip-horizontal': { icon: 'grip-horizontal' },
  'drag-handle': { icon: ['nep', 'drag-handle'] },
  'grip-lines': { icon: 'grip-lines' },
  'group-items': { icon: ['nep', 'group-items'] },
  'group-add': { icon: ['nep', 'group-add'] },
  hashtag: { icon: 'hashtag' },
  heading: { icon: 'heading' },
  image: { icon: ['far', 'image'] },
  'image-set': [
    { icon: ['nep', 'left-bracket'], transform: 'shrink-4 left-8' },
    { icon: ['far', 'image'], transform: 'shrink-5' },
    { icon: ['nep', 'left-bracket'], transform: 'shrink-4 flip-h right-8' },
  ],
  'info-circle': { icon: 'info-circle' },
  integer: { icon: ['nep', 'integer'] },
  'i-circled': [{ icon: ['far', 'circle'] }, { icon: 'info', transform: 'shrink-7' }],
  italic: { icon: 'italic' },
  kaggle: { icon: ['fab', 'kaggle'] },
  'left-bracket': { icon: ['nep', 'left-bracket'] },
  'light-bulb': { icon: ['far', 'lightbulb'] },
  'less-than': { icon: 'less-than' },
  'level-up': { icon: 'level-up-alt', flip: 'horizontal' },
  link: { icon: 'link' },
  linkedin: { icon: ['fab', 'linkedin'] },
  linux: { icon: ['fab', 'linux'] },
  list: { icon: 'list' },
  'list-alt': { icon: ['far', 'list-alt'] },
  'list-ol': { icon: 'list-ol' },
  'list-ul': { icon: 'list-ul' },
  lock: { icon: ['nep', 'lock'] },
  'lock-open': { icon: ['nep', 'lock-open'] },
  'long-arrow-alt-left': { icon: 'long-arrow-alt-left' },
  'long-arrow-alt-right': { icon: 'long-arrow-alt-right' },
  minus: { icon: 'minus' },
  'minus-circle': { icon: 'minus-circle' },
  'network-wired': { icon: 'network-wired' },
  'not-equal': { icon: 'not-equal' },
  notification: { icon: ['nep', 'notification'] },
  number: { icon: ['nep', 'number'], transform: 'grow-2' },
  paragraph: { icon: 'paragraph' },
  pen: { icon: 'pen' },
  pause: [
    { icon: 'minus', transform: 'left-4 rotate-90' },
    { icon: 'minus', transform: 'right-4 rotate-90' },
  ],
  plus: { icon: ['nep', 'plus'] },
  'play-circle': { icon: ['nep', 'play-circle'] },
  'plus-circle': { icon: 'plus-circle' },
  'plus-square': { icon: ['far', 'plus-square'] },
  project: { icon: ['nep', 'project'] },
  python: { icon: ['fab', 'python'] },
  people: { icon: ['nep', 'people'] },
  'question-circle': { icon: ['far', 'question-circle'] },
  random: { icon: 'random' },
  receipt: { icon: 'receipt' },
  // 'resize': { icon: ['nep', 'resize'] },
  retweet: { icon: 'retweet' },
  'right-bracket': { icon: ['nep', 'left-bracket'], transform: 'flip-h' },
  rocket: { icon: 'rocket' },
  'service-account': { icon: ['nep', 'service-account'] },
  search: { icon: ['nep', 'search'] },
  'search-history': { icon: ['nep', 'search-history'] },
  'sidebar-left': { icon: ['nep', 'sidebar-left'] },
  'sort-up': { icon: ['nep', 'sort-ascending-1'] },
  'sort-down': { icon: ['nep', 'sort-descending-2'] },
  'source-code': { icon: ['nep', 'source-code'] },
  spinner: { icon: 'spinner' },
  square: { icon: 'square' },
  star: { icon: 'star' },
  status: { icon: ['nep', 'status'] },
  stopwatch: { icon: 'stopwatch' },
  strikethrough: { icon: 'strikethrough' },
  string: { icon: ['nep', 'string'] },
  'string-series': { icon: ['nep', 'string-series'] },
  'string-set': { icon: ['nep', 'string-set'] },
  subscription: { icon: ['nep', 'subscription'] },
  subtract: { icon: ['nep', 'subtract'] },
  'sliders-h': { icon: 'sliders-h' },
  table: { icon: ['nep', 'table'] },
  tachometer: { icon: 'tachometer-alt' },
  tag: { icon: 'tag' },
  'test-tube': { icon: ['nep', 'test-tube'] },
  'th-large': { icon: ['fas', 'th-large'] },
  thumbtack: { icon: 'thumbtack' },
  times: { icon: 'times' },
  'times-circle': { icon: 'times-circle' },
  'toggle-off': { icon: 'toggle-off' },
  'toggle-on': { icon: 'toggle-on' },
  trash: { icon: ['nep', 'trash'] },
  twitter: { icon: ['fab', 'twitter'] },
  'expand-left-right': { icon: ['nep', 'expand-left-right'] },
  'expand-up-down': { icon: ['nep', 'expand-up-down'] },
  undo: { icon: 'undo-alt' },
  unpin: { icon: ['nep', 'unpin'] },
  upload: { icon: 'cloud-upload-alt' },
  user: { icon: ['far', 'user'] },
  users: { icon: 'users' },
  'user-cog': { icon: ['fas', 'user-cog'] },
  'user-friends': { icon: 'user-friends' },
  windows: { icon: ['fab', 'windows'] },
  axis: { icon: ['nep', 'axis'] },
  enter: { icon: ['nep', 'enter'] },
  'manage-group': { icon: ['nep', 'manage-group'] },
  next: { icon: ['nep', 'next'] },
  'parallel-coordinates': { icon: ['nep', 'parallel-coordinates'] },
  metadata: { icon: ['nep', 'metadata'] },
  artifacts: { icon: ['nep', 'artifacts'] },
  images: { icon: ['nep', 'images'] },
  dashboards: { icon: ['nep', 'dashboards'] },
  monitoring: { icon: ['nep', 'monitoring'] },
  'bar-chart': { icon: ['nep', 'bar-chart'] },
  'right-edge-point': { icon: ['nep', 'right-edge-point'] },
  'left-edge-point': { icon: ['nep', 'left-edge-point'] },
  'single-edge-point': { icon: ['nep', 'single-edge-point'] },
  construction: { icon: ['nep', 'construction'] },
  approximation: { icon: ['nep', 'approximation'] },
  'dot-line': { icon: ['nep', 'dot-line'] },
  'leaf-runs': { icon: ['nep', 'leaf-runs'] },
  lineage: { icon: ['nep', 'lineage'] },
  'curved-dots': { icon: ['nep', 'curved-dots'] },
  'x-axis-options': { icon: ['nep', 'x-axis-options'] },
  'view-mode': { icon: ['nep', 'view-mode'] },
  'pen-thin': { icon: ['nep', 'pen-thin'] },
  'full-view': { icon: ['nep', 'full-view'] },
  'y-range-disable': { icon: ['nep', 'y-range-disable'] },
  'steps-range-disable': { icon: ['nep', 'steps-range-disable'] },
  'steps-range': { icon: ['nep', 'steps-range'] },
  padlock: { icon: ['nep', 'padlock'] },
  'chart-residuals': { icon: ['nep', 'chart-residuals'] },
  regexp: { icon: ['nep', 'regexp'] },
  scatterplot: { icon: ['nep', 'scatterplot'] },
  'y-axis': { icon: ['nep', 'y-axis'] },
  'x-axis': { icon: ['nep', 'x-axis'] },
  'neptune-wave': { icon: ['nep', 'neptune-wave'] },
  'neptune-ai': { icon: ['nep', 'neptune-ai'] },
};

export function getGlyphNames(): GlyphName[] {
  return Object.keys(GLYPHS) as GlyphName[];
}

export function getGlyph(glyph: GlyphName): GlyphDefinition {
  return GLYPHS[glyph] as GlyphDefinition;
}

export function isGlyph(param: any): param is GlyphName {
  return getGlyphNames().includes(param as GlyphName);
}
