/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AuthorizedUserDTO,
  ClientConfig,
  ComponentStatus,
  ConfigInfo,
  CreateSessionParamsDTO,
  CreditCardDTO,
  GlobalConfiguration,
  IntercomDataDTO,
  InvitationListDTO,
  InvoiceDTO,
  Link,
  LinkDTO,
  LoginActionsListDTO,
  MonitoringHoursPurchaseRequestDTO,
  MonitoringTimeAndPackageHistoryDTO,
  MonitoringTimeHistoryDTO,
  MonitoringTimePackageListDTO,
  MonitoringTimeStatsDTO,
  MonitoringTimeStatusDTO,
  MonitoringTimeUsageDTO,
  NeptuneApiToken,
  NeptuneOauthToken,
  NewOrganizationInvitationsDTO,
  NewOrganizationMemberDTO,
  NewProjectDTO,
  NewProjectInvitationDTO,
  NewProjectMemberDTO,
  NewProjectServiceAccountDTO,
  NewReservationDTO,
  NewServiceAccountDTO,
  OrganizationCreationParamsDTO,
  OrganizationDTO,
  OrganizationInvitationDTO,
  OrganizationInvitationUpdateDTO,
  OrganizationInvitationsDTO,
  OrganizationLimitsDTO,
  OrganizationMemberDTO,
  OrganizationMemberUpdateDTO,
  OrganizationNameAvailableDTO,
  OrganizationOwnerListDTO,
  OrganizationPricingDTO,
  OrganizationUpdateDTO,
  OrganizationWithRoleDTO,
  PasswordChangeDTO,
  PastInvoicesDTO,
  ProjectDTO,
  ProjectInvitationDTO,
  ProjectInvitationUpdateDTO,
  ProjectKeyAvailabilityDTO,
  ProjectKeyDTO,
  ProjectListDTO,
  ProjectMemberDTO,
  ProjectMemberUpdateDTO,
  ProjectMembersDTO,
  ProjectMetadataDTO,
  ProjectServiceAccountsUpdateDTO,
  ProjectStorageLimitTraitDTO,
  ProjectUpdateDTO,
  ProjectUsageListDTO,
  ProjectVisibilityRestrictedUpdateRequestDTO,
  ProjectWithRoleDTO,
  PropertyDTO,
  PropertyListDTO,
  PublicUserProfileDTO,
  PurchaseResultDTO,
  RegistrationSurveyDTO,
  ResendOrganizationInvitationDTO,
  ResendProjectInvitationDTO,
  ServiceAccountDTO,
  ServiceAccountWithRoleDTO,
  SessionDTO,
  SetProjectStorageLimitDTO,
  StoragePurchaseRequestDTO,
  StorageUsage,
  SubscriptionCancelInfoDTO,
  UpgradePurchaseRequestDTO,
  UserListDTO,
  UserMetadataDTO,
  UserProfileDTO,
  UserProfileLinkDTO,
  UserProfileUpdateDTO,
  UsernameValidationStatusDTO,
  WorkspaceConfig,
  WorkspaceStatusDTO,
} from '../models/index';
import {
    AuthorizedUserDTOFromJSON,
    AuthorizedUserDTOToJSON,
    ClientConfigFromJSON,
    ClientConfigToJSON,
    ComponentStatusFromJSON,
    ComponentStatusToJSON,
    ConfigInfoFromJSON,
    ConfigInfoToJSON,
    CreateSessionParamsDTOFromJSON,
    CreateSessionParamsDTOToJSON,
    CreditCardDTOFromJSON,
    CreditCardDTOToJSON,
    GlobalConfigurationFromJSON,
    GlobalConfigurationToJSON,
    IntercomDataDTOFromJSON,
    IntercomDataDTOToJSON,
    InvitationListDTOFromJSON,
    InvitationListDTOToJSON,
    InvoiceDTOFromJSON,
    InvoiceDTOToJSON,
    LinkFromJSON,
    LinkToJSON,
    LinkDTOFromJSON,
    LinkDTOToJSON,
    LoginActionsListDTOFromJSON,
    LoginActionsListDTOToJSON,
    MonitoringHoursPurchaseRequestDTOFromJSON,
    MonitoringHoursPurchaseRequestDTOToJSON,
    MonitoringTimeAndPackageHistoryDTOFromJSON,
    MonitoringTimeAndPackageHistoryDTOToJSON,
    MonitoringTimeHistoryDTOFromJSON,
    MonitoringTimeHistoryDTOToJSON,
    MonitoringTimePackageListDTOFromJSON,
    MonitoringTimePackageListDTOToJSON,
    MonitoringTimeStatsDTOFromJSON,
    MonitoringTimeStatsDTOToJSON,
    MonitoringTimeStatusDTOFromJSON,
    MonitoringTimeStatusDTOToJSON,
    MonitoringTimeUsageDTOFromJSON,
    MonitoringTimeUsageDTOToJSON,
    NeptuneApiTokenFromJSON,
    NeptuneApiTokenToJSON,
    NeptuneOauthTokenFromJSON,
    NeptuneOauthTokenToJSON,
    NewOrganizationInvitationsDTOFromJSON,
    NewOrganizationInvitationsDTOToJSON,
    NewOrganizationMemberDTOFromJSON,
    NewOrganizationMemberDTOToJSON,
    NewProjectDTOFromJSON,
    NewProjectDTOToJSON,
    NewProjectInvitationDTOFromJSON,
    NewProjectInvitationDTOToJSON,
    NewProjectMemberDTOFromJSON,
    NewProjectMemberDTOToJSON,
    NewProjectServiceAccountDTOFromJSON,
    NewProjectServiceAccountDTOToJSON,
    NewReservationDTOFromJSON,
    NewReservationDTOToJSON,
    NewServiceAccountDTOFromJSON,
    NewServiceAccountDTOToJSON,
    OrganizationCreationParamsDTOFromJSON,
    OrganizationCreationParamsDTOToJSON,
    OrganizationDTOFromJSON,
    OrganizationDTOToJSON,
    OrganizationInvitationDTOFromJSON,
    OrganizationInvitationDTOToJSON,
    OrganizationInvitationUpdateDTOFromJSON,
    OrganizationInvitationUpdateDTOToJSON,
    OrganizationInvitationsDTOFromJSON,
    OrganizationInvitationsDTOToJSON,
    OrganizationLimitsDTOFromJSON,
    OrganizationLimitsDTOToJSON,
    OrganizationMemberDTOFromJSON,
    OrganizationMemberDTOToJSON,
    OrganizationMemberUpdateDTOFromJSON,
    OrganizationMemberUpdateDTOToJSON,
    OrganizationNameAvailableDTOFromJSON,
    OrganizationNameAvailableDTOToJSON,
    OrganizationOwnerListDTOFromJSON,
    OrganizationOwnerListDTOToJSON,
    OrganizationPricingDTOFromJSON,
    OrganizationPricingDTOToJSON,
    OrganizationUpdateDTOFromJSON,
    OrganizationUpdateDTOToJSON,
    OrganizationWithRoleDTOFromJSON,
    OrganizationWithRoleDTOToJSON,
    PasswordChangeDTOFromJSON,
    PasswordChangeDTOToJSON,
    PastInvoicesDTOFromJSON,
    PastInvoicesDTOToJSON,
    ProjectDTOFromJSON,
    ProjectDTOToJSON,
    ProjectInvitationDTOFromJSON,
    ProjectInvitationDTOToJSON,
    ProjectInvitationUpdateDTOFromJSON,
    ProjectInvitationUpdateDTOToJSON,
    ProjectKeyAvailabilityDTOFromJSON,
    ProjectKeyAvailabilityDTOToJSON,
    ProjectKeyDTOFromJSON,
    ProjectKeyDTOToJSON,
    ProjectListDTOFromJSON,
    ProjectListDTOToJSON,
    ProjectMemberDTOFromJSON,
    ProjectMemberDTOToJSON,
    ProjectMemberUpdateDTOFromJSON,
    ProjectMemberUpdateDTOToJSON,
    ProjectMembersDTOFromJSON,
    ProjectMembersDTOToJSON,
    ProjectMetadataDTOFromJSON,
    ProjectMetadataDTOToJSON,
    ProjectServiceAccountsUpdateDTOFromJSON,
    ProjectServiceAccountsUpdateDTOToJSON,
    ProjectStorageLimitTraitDTOFromJSON,
    ProjectStorageLimitTraitDTOToJSON,
    ProjectUpdateDTOFromJSON,
    ProjectUpdateDTOToJSON,
    ProjectUsageListDTOFromJSON,
    ProjectUsageListDTOToJSON,
    ProjectVisibilityRestrictedUpdateRequestDTOFromJSON,
    ProjectVisibilityRestrictedUpdateRequestDTOToJSON,
    ProjectWithRoleDTOFromJSON,
    ProjectWithRoleDTOToJSON,
    PropertyDTOFromJSON,
    PropertyDTOToJSON,
    PropertyListDTOFromJSON,
    PropertyListDTOToJSON,
    PublicUserProfileDTOFromJSON,
    PublicUserProfileDTOToJSON,
    PurchaseResultDTOFromJSON,
    PurchaseResultDTOToJSON,
    RegistrationSurveyDTOFromJSON,
    RegistrationSurveyDTOToJSON,
    ResendOrganizationInvitationDTOFromJSON,
    ResendOrganizationInvitationDTOToJSON,
    ResendProjectInvitationDTOFromJSON,
    ResendProjectInvitationDTOToJSON,
    ServiceAccountDTOFromJSON,
    ServiceAccountDTOToJSON,
    ServiceAccountWithRoleDTOFromJSON,
    ServiceAccountWithRoleDTOToJSON,
    SessionDTOFromJSON,
    SessionDTOToJSON,
    SetProjectStorageLimitDTOFromJSON,
    SetProjectStorageLimitDTOToJSON,
    StoragePurchaseRequestDTOFromJSON,
    StoragePurchaseRequestDTOToJSON,
    StorageUsageFromJSON,
    StorageUsageToJSON,
    SubscriptionCancelInfoDTOFromJSON,
    SubscriptionCancelInfoDTOToJSON,
    UpgradePurchaseRequestDTOFromJSON,
    UpgradePurchaseRequestDTOToJSON,
    UserListDTOFromJSON,
    UserListDTOToJSON,
    UserMetadataDTOFromJSON,
    UserMetadataDTOToJSON,
    UserProfileDTOFromJSON,
    UserProfileDTOToJSON,
    UserProfileLinkDTOFromJSON,
    UserProfileLinkDTOToJSON,
    UserProfileUpdateDTOFromJSON,
    UserProfileUpdateDTOToJSON,
    UsernameValidationStatusDTOFromJSON,
    UsernameValidationStatusDTOToJSON,
    WorkspaceConfigFromJSON,
    WorkspaceConfigToJSON,
    WorkspaceStatusDTOFromJSON,
    WorkspaceStatusDTOToJSON,
} from '../models/index';

export interface AcceptOrganizationInvitationRequest {
    invitationId: string;
}

export interface AcceptProjectInvitationRequest {
    invitationId: string;
}

export interface ActivateServiceAccountRequest {
    organizationIdentifier: string;
    serviceAccountId: string;
}

export interface AddMonitoringHoursRequest {
    organizationIdentifier: string;
    purchase: MonitoringHoursPurchaseRequestDTO;
}

export interface AddOrganizationMemberRequest {
    organizationIdentifier: string;
    member: NewOrganizationMemberDTO;
}

export interface AddProjectMemberRequest {
    projectIdentifier: string;
    member: NewProjectMemberDTO;
}

export interface AddProjectServiceAccountRequest {
    projectIdentifier: string;
    account: NewProjectServiceAccountDTO;
}

export interface AddUserProfileLinkRequest {
    link: UserProfileLinkDTO;
}

export interface CancelSubscriptionRequest {
    organizationId: string;
}

export interface CancellationSurveyRequest {
    organizationId: string;
    survey: SubscriptionCancelInfoDTO;
}

export interface ChangePasswordRequest {
    passwordToUpdate: PasswordChangeDTO;
}

export interface ChangeStorageRequest {
    organizationIdentifier: string;
    purchase: StoragePurchaseRequestDTO;
}

export interface CreateCardUpdateSessionRequest {
    organizationIdentifier: string;
    createSessionParams: CreateSessionParamsDTO;
}

export interface CreateCheckoutSessionRequest {
    organizationIdentifier: string;
    purchase: UpgradePurchaseRequestDTO;
}

export interface CreateOrganizationRequest {
    organizationToCreate: OrganizationCreationParamsDTO;
}

export interface CreateOrganizationInvitationsRequest {
    newOrganizationInvitations: NewOrganizationInvitationsDTO;
}

export interface CreateProjectRequest {
    projectToCreate: NewProjectDTO;
}

export interface CreateProjectInvitationRequest {
    newProjectInvitation: NewProjectInvitationDTO;
}

export interface CreateReservationRequest {
    newReservation: NewReservationDTO;
}

export interface CreateServiceAccountRequest {
    organizationIdentifier: string;
    addToAllProjects: boolean;
    newServiceAccount: NewServiceAccountDTO;
}

export interface DeactivateServiceAccountRequest {
    organizationIdentifier: string;
    serviceAccountId: string;
}

export interface DeleteOrganizationRequest {
    organizationId: string;
}

export interface DeleteOrganizationMemberRequest {
    organizationIdentifier: string;
    userId: string;
}

export interface DeleteProjectRequest {
    projectIdentifier: string;
}

export interface DeleteProjectMemberRequest {
    projectIdentifier: string;
    userId: string;
}

export interface DeleteProjectServiceAccountRequest {
    projectIdentifier: string;
    serviceAccountId: string;
}

export interface DeleteScopedPropertyRequest {
    key: string;
    propertyType: string;
    scope: string;
}

export interface DeleteStorageLimitRequest {
    projectIdentifier: string;
}

export interface DeleteUserProfileLinkRequest {
    link: LinkDTO;
}

export interface DetachCreditCardRequest {
    organizationIdentifier: string;
}

export interface ExchangeApiTokenRequest {
    xNeptuneApiToken: string;
}

export interface FeedIntercomRequest {
    intercomData: IntercomDataDTO;
}

export interface FilterOrganizationsRequest {
    ids: Array<string>;
}

export interface GenerateProjectKeyRequest {
    organizationId: string;
    projectName: string;
}

export interface GetClientConfigRequest {
    xNeptuneApiToken?: string;
    alpha?: string;
}

export interface GetCreditCardRequest {
    organizationIdentifier: string;
}

export interface GetOrganizationRequest {
    organization: string;
}

export interface GetOrganizationAvatarRequest {
    organizationName: string;
}

export interface GetOrganizationInvitationRequest {
    invitationId: string;
}

export interface GetOrganizationLimitsRequest {
    organizationIdentifier: string;
}

export interface GetOrganizationPricingRequest {
    organizationIdentifier: string;
}

export interface GetPastInvoicesRequest {
    organizationIdentifier: string;
}

export interface GetProjectRequest {
    projectIdentifier: string;
}

export interface GetProjectAvatarRequest {
    organizationName: string;
    projectName: string;
}

export interface GetProjectBackgroundRequest {
    organizationName: string;
    projectName: string;
}

export interface GetProjectDetailsRequest {
    projectIdentifier: string;
}

export interface GetProjectInvitationRequest {
    invitationId: string;
}

export interface GetProjectMetadataRequest {
    projectIdentifier: string;
}

export interface GetScopedPropertyRequest {
    key: string;
    propertyType: string;
    scope: string;
}

export interface GetServiceAccountApiTokenRequest {
    serviceAccountId: string;
}

export interface GetSsoConfigRequest {
    workspaceName: string;
}

export interface GetUpcomingInvoiceRequest {
    organizationIdentifier: string;
}

export interface GetUserAvatarRequest {
    username: string;
}

export interface IsOrganizationNameAvailableRequest {
    organizationName: string;
}

export interface LeaveProjectRequest {
    projectIdentifier: string;
}

export interface ListMembersRequest {
    organizationIdentifier?: string;
    projectIdentifier?: string;
    usernamePrefix?: string;
    offset?: number;
    limit?: number;
}

export interface ListOrganizationMembersRequest {
    organizationIdentifier: string;
}

export interface ListOrganizationProjectsRequest {
    organizationIdentifier: string;
    searchTerm?: string;
    sortBy?: Array<string>;
    sortDirection?: Array<string>;
    offset?: number;
    limit?: number;
    archived?: boolean;
}

export interface ListOwnersRequest {
    organizationIdentifier: string;
}

export interface ListProjectMembersRequest {
    projectIdentifier: string;
}

export interface ListProjectServiceAccountsRequest {
    projectIdentifier: string;
}

export interface ListProjectsRequest {
    organizationIdentifier?: string;
    userRelation?: string;
    searchTerm?: string;
    sortBy?: Array<string>;
    sortDirection?: Array<string>;
    offset?: number;
    limit?: number;
}

export interface ListProjectsMembersRequest {
    projectIdentifier: Array<string>;
    includeInvitations?: boolean;
}

export interface ListProjectsMembersPostRequest {
    projectIdentifiers: Array<string>;
    includeInvitations?: boolean;
}

export interface ListPublicProjectsRequest {
    searchTerm?: string;
    sortBy?: Array<string>;
    sortDirection?: Array<string>;
    offset?: number;
    limit?: number;
}

export interface ListRolesForUserRequest {
    projectIdentifiers?: Array<Array<string>>;
}

export interface ListScopedPropertiesRequest {
    propertyType: string;
    scope: string;
}

export interface ListServiceAccountsRequest {
    organizationIdentifier: string;
    deactivated?: boolean;
}

export interface ListUserProjectsRequest {
    viewedUsername: string;
    projectsForUserMode: string;
    organizationIdentifier?: string;
    userRelation?: string;
    searchTerm?: string;
    sortBy?: Array<string>;
    sortDirection?: Array<string>;
    offset?: number;
    limit?: number;
    archived?: boolean;
}

export interface ListUsersRequest {
    username: Array<string>;
}

export interface MonitoringTimePackagesRequest {
    organizationIdentifier: string;
    limit: number;
    offset: number;
    dateMin?: Date;
    dateMax?: Date;
}

export interface MonitoringTimeStatsRequest {
    projectIdentifier: string;
}

export interface MonitoringTimeStatusRequest {
    organizationIdentifier: string;
}

export interface MonitoringTimeTopProjects30DaysRequest {
    organizationIdentifier: string;
    sortBy: string;
}

export interface MonitoringTimeUsageRequest {
    projectIdentifier: string;
}

export interface OrganizationMonitoringTimeUsageRequest {
    organizationIdentifier: string;
    dateMin?: Date;
    dateMax?: Date;
}

export interface ProjectMonitoringTimeUsageRequest {
    projectIdentifier: string;
    dateMin?: Date;
    dateMax?: Date;
}

export interface ResendExistingOrganizationInvitationRequest {
    invitationId: string;
}

export interface ResendOrganizationInvitationRequest {
    resendOrganizationInvitation: ResendOrganizationInvitationDTO;
}

export interface ResendProjectInvitationRequest {
    resendProjectInvitation: ResendProjectInvitationDTO;
}

export interface RevokeOrganizationInvitationRequest {
    invitationId: string;
}

export interface RevokeProjectInvitationRequest {
    invitationId: string;
}

export interface RevokeServiceAccountApiTokenRequest {
    serviceAccountId: string;
}

export interface SendRegistrationSurveyRequest {
    survey: RegistrationSurveyDTO;
}

export interface SetScopedPropertyRequest {
    propertyType: string;
    scope: string;
    propertyBody: PropertyDTO;
}

export interface SetStorageLimitRequest {
    projectIdentifier: string;
    storageLimit: SetProjectStorageLimitDTO;
}

export interface SetUserMetadataRequest {
    metadata: UserMetadataDTO;
}

export interface SetUsernameRequest {
    username: string;
}

export interface StorageUsageRequest {
    organizationIdentifier?: string;
    projectIdentifier?: string;
}

export interface UpdateActiveProjectsQuotaRequest {
    organizationIdentifier: string;
    quota: number;
}

export interface UpdateLastViewedRequest {
    projectId: string;
}

export interface UpdateOrganizationRequest {
    organizationId: string;
    organizationToUpdate: OrganizationUpdateDTO;
}

export interface UpdateOrganizationAvatarRequest {
    organizationId: string;
    avatarFile: Blob;
}

export interface UpdateOrganizationInvitationRequest {
    invitationId: string;
    update: OrganizationInvitationUpdateDTO;
}

export interface UpdateOrganizationMemberRequest {
    organizationIdentifier: string;
    userId: string;
    member: OrganizationMemberUpdateDTO;
}

export interface UpdateProjectRequest {
    projectIdentifier: string;
    projectToUpdate: ProjectUpdateDTO;
}

export interface UpdateProjectAvatarRequest {
    projectId: string;
    avatarFile: Blob;
}

export interface UpdateProjectBackgroundRequest {
    projectId: string;
    backgroundFile: Blob;
}

export interface UpdateProjectInvitationRequest {
    invitationId: string;
    update: ProjectInvitationUpdateDTO;
}

export interface UpdateProjectMemberRequest {
    projectIdentifier: string;
    userId: string;
    member: ProjectMemberUpdateDTO;
}

export interface UpdateProjectServiceAccountRequest {
    projectIdentifier: string;
    serviceAccountId: string;
    member: ProjectServiceAccountsUpdateDTO;
}

export interface UpdateUserProfileRequest {
    userProfileUpdate: UserProfileUpdateDTO;
}

export interface UpdateUserProfileAvatarRequest {
    avatarFile: Blob;
}

export interface UpdateVisibilityRestrictionsRequest {
    organizationIdentifier: string;
    updateRequest: ProjectVisibilityRestrictedUpdateRequestDTO;
}

export interface ValidateUsernameRequest {
    username: string;
}

export interface VerifyProjectKeyRequest {
    organizationIdentifier: string;
    projectKey: string;
}

export interface WorkspaceStatusRequest {
    organizationIdentifier: string;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     */
    async acceptOrganizationInvitationRaw(requestParameters: AcceptOrganizationInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['invitationId'] == null) {
            throw new runtime.RequiredError(
                'invitationId',
                'Required parameter "invitationId" was null or undefined when calling acceptOrganizationInvitation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/invitations/organization/{invitationId}/accept`.replace(`{${"invitationId"}}`, encodeURIComponent(String(requestParameters['invitationId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async acceptOrganizationInvitation(requestParameters: AcceptOrganizationInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.acceptOrganizationInvitationRaw(requestParameters, initOverrides);
    }

    /**
     */
    async acceptProjectInvitationRaw(requestParameters: AcceptProjectInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['invitationId'] == null) {
            throw new runtime.RequiredError(
                'invitationId',
                'Required parameter "invitationId" was null or undefined when calling acceptProjectInvitation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/invitations/project/{invitationId}/accept`.replace(`{${"invitationId"}}`, encodeURIComponent(String(requestParameters['invitationId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async acceptProjectInvitation(requestParameters: AcceptProjectInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.acceptProjectInvitationRaw(requestParameters, initOverrides);
    }

    /**
     */
    async activateServiceAccountRaw(requestParameters: ActivateServiceAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['organizationIdentifier'] == null) {
            throw new runtime.RequiredError(
                'organizationIdentifier',
                'Required parameter "organizationIdentifier" was null or undefined when calling activateServiceAccount().'
            );
        }

        if (requestParameters['serviceAccountId'] == null) {
            throw new runtime.RequiredError(
                'serviceAccountId',
                'Required parameter "serviceAccountId" was null or undefined when calling activateServiceAccount().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['organizationIdentifier'] != null) {
            queryParameters['organizationIdentifier'] = requestParameters['organizationIdentifier'];
        }

        if (requestParameters['serviceAccountId'] != null) {
            queryParameters['serviceAccountId'] = requestParameters['serviceAccountId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/serviceAccounts/activate`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async activateServiceAccount(requestParameters: ActivateServiceAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.activateServiceAccountRaw(requestParameters, initOverrides);
    }

    /**
     */
    async addMonitoringHoursRaw(requestParameters: AddMonitoringHoursRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PurchaseResultDTO>> {
        if (requestParameters['organizationIdentifier'] == null) {
            throw new runtime.RequiredError(
                'organizationIdentifier',
                'Required parameter "organizationIdentifier" was null or undefined when calling addMonitoringHours().'
            );
        }

        if (requestParameters['purchase'] == null) {
            throw new runtime.RequiredError(
                'purchase',
                'Required parameter "purchase" was null or undefined when calling addMonitoringHours().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['organizationIdentifier'] != null) {
            queryParameters['organizationIdentifier'] = requestParameters['organizationIdentifier'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/payments/limits/addMonitoringHours`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MonitoringHoursPurchaseRequestDTOToJSON(requestParameters['purchase']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PurchaseResultDTOFromJSON(jsonValue));
    }

    /**
     */
    async addMonitoringHours(requestParameters: AddMonitoringHoursRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PurchaseResultDTO> {
        const response = await this.addMonitoringHoursRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async addOrganizationMemberRaw(requestParameters: AddOrganizationMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrganizationMemberDTO>> {
        if (requestParameters['organizationIdentifier'] == null) {
            throw new runtime.RequiredError(
                'organizationIdentifier',
                'Required parameter "organizationIdentifier" was null or undefined when calling addOrganizationMember().'
            );
        }

        if (requestParameters['member'] == null) {
            throw new runtime.RequiredError(
                'member',
                'Required parameter "member" was null or undefined when calling addOrganizationMember().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/organizations2/{organizationIdentifier}/members`.replace(`{${"organizationIdentifier"}}`, encodeURIComponent(String(requestParameters['organizationIdentifier']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewOrganizationMemberDTOToJSON(requestParameters['member']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationMemberDTOFromJSON(jsonValue));
    }

    /**
     */
    async addOrganizationMember(requestParameters: AddOrganizationMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrganizationMemberDTO> {
        const response = await this.addOrganizationMemberRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async addProjectMemberRaw(requestParameters: AddProjectMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectMemberDTO>> {
        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling addProjectMember().'
            );
        }

        if (requestParameters['member'] == null) {
            throw new runtime.RequiredError(
                'member',
                'Required parameter "member" was null or undefined when calling addProjectMember().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/projects/members`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewProjectMemberDTOToJSON(requestParameters['member']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectMemberDTOFromJSON(jsonValue));
    }

    /**
     */
    async addProjectMember(requestParameters: AddProjectMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectMemberDTO> {
        const response = await this.addProjectMemberRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async addProjectServiceAccountRaw(requestParameters: AddProjectServiceAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ServiceAccountWithRoleDTO>> {
        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling addProjectServiceAccount().'
            );
        }

        if (requestParameters['account'] == null) {
            throw new runtime.RequiredError(
                'account',
                'Required parameter "account" was null or undefined when calling addProjectServiceAccount().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/projects/service_accounts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewProjectServiceAccountDTOToJSON(requestParameters['account']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceAccountWithRoleDTOFromJSON(jsonValue));
    }

    /**
     */
    async addProjectServiceAccount(requestParameters: AddProjectServiceAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ServiceAccountWithRoleDTO> {
        const response = await this.addProjectServiceAccountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async addUserProfileLinkRaw(requestParameters: AddUserProfileLinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['link'] == null) {
            throw new runtime.RequiredError(
                'link',
                'Required parameter "link" was null or undefined when calling addUserProfileLink().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/userProfile/links`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserProfileLinkDTOToJSON(requestParameters['link']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async addUserProfileLink(requestParameters: AddUserProfileLinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.addUserProfileLinkRaw(requestParameters, initOverrides);
    }

    /**
     */
    async authorizeRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuthorizedUserDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/authorization/authorize`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthorizedUserDTOFromJSON(jsonValue));
    }

    /**
     */
    async authorize(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuthorizedUserDTO> {
        const response = await this.authorizeRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async cancelSubscriptionRaw(requestParameters: CancelSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['organizationId'] == null) {
            throw new runtime.RequiredError(
                'organizationId',
                'Required parameter "organizationId" was null or undefined when calling cancelSubscription().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/organizations2/{organizationId}/cancelSubscription`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters['organizationId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async cancelSubscription(requestParameters: CancelSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.cancelSubscriptionRaw(requestParameters, initOverrides);
    }

    /**
     */
    async cancellationSurveyRaw(requestParameters: CancellationSurveyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['organizationId'] == null) {
            throw new runtime.RequiredError(
                'organizationId',
                'Required parameter "organizationId" was null or undefined when calling cancellationSurvey().'
            );
        }

        if (requestParameters['survey'] == null) {
            throw new runtime.RequiredError(
                'survey',
                'Required parameter "survey" was null or undefined when calling cancellationSurvey().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/backend/v1/organizations2/{organizationId}/cancellationSurvey`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters['organizationId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SubscriptionCancelInfoDTOToJSON(requestParameters['survey']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async cancellationSurvey(requestParameters: CancellationSurveyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.cancellationSurveyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async changePasswordRaw(requestParameters: ChangePasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PasswordChangeDTO>> {
        if (requestParameters['passwordToUpdate'] == null) {
            throw new runtime.RequiredError(
                'passwordToUpdate',
                'Required parameter "passwordToUpdate" was null or undefined when calling changePassword().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/userProfile/password`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PasswordChangeDTOToJSON(requestParameters['passwordToUpdate']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PasswordChangeDTOFromJSON(jsonValue));
    }

    /**
     */
    async changePassword(requestParameters: ChangePasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PasswordChangeDTO> {
        const response = await this.changePasswordRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeStorageRaw(requestParameters: ChangeStorageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PurchaseResultDTO>> {
        if (requestParameters['organizationIdentifier'] == null) {
            throw new runtime.RequiredError(
                'organizationIdentifier',
                'Required parameter "organizationIdentifier" was null or undefined when calling changeStorage().'
            );
        }

        if (requestParameters['purchase'] == null) {
            throw new runtime.RequiredError(
                'purchase',
                'Required parameter "purchase" was null or undefined when calling changeStorage().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['organizationIdentifier'] != null) {
            queryParameters['organizationIdentifier'] = requestParameters['organizationIdentifier'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/payments/limits/changeStorage`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StoragePurchaseRequestDTOToJSON(requestParameters['purchase']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PurchaseResultDTOFromJSON(jsonValue));
    }

    /**
     */
    async changeStorage(requestParameters: ChangeStorageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PurchaseResultDTO> {
        const response = await this.changeStorageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async configInfoGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConfigInfo>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/configInfo`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConfigInfoFromJSON(jsonValue));
    }

    /**
     */
    async configInfoGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConfigInfo> {
        const response = await this.configInfoGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async createCardUpdateSessionRaw(requestParameters: CreateCardUpdateSessionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SessionDTO>> {
        if (requestParameters['organizationIdentifier'] == null) {
            throw new runtime.RequiredError(
                'organizationIdentifier',
                'Required parameter "organizationIdentifier" was null or undefined when calling createCardUpdateSession().'
            );
        }

        if (requestParameters['createSessionParams'] == null) {
            throw new runtime.RequiredError(
                'createSessionParams',
                'Required parameter "createSessionParams" was null or undefined when calling createCardUpdateSession().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/payments/{organizationIdentifier}/creditCard`.replace(`{${"organizationIdentifier"}}`, encodeURIComponent(String(requestParameters['organizationIdentifier']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateSessionParamsDTOToJSON(requestParameters['createSessionParams']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SessionDTOFromJSON(jsonValue));
    }

    /**
     */
    async createCardUpdateSession(requestParameters: CreateCardUpdateSessionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SessionDTO> {
        const response = await this.createCardUpdateSessionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createCheckoutSessionRaw(requestParameters: CreateCheckoutSessionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PurchaseResultDTO>> {
        if (requestParameters['organizationIdentifier'] == null) {
            throw new runtime.RequiredError(
                'organizationIdentifier',
                'Required parameter "organizationIdentifier" was null or undefined when calling createCheckoutSession().'
            );
        }

        if (requestParameters['purchase'] == null) {
            throw new runtime.RequiredError(
                'purchase',
                'Required parameter "purchase" was null or undefined when calling createCheckoutSession().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['organizationIdentifier'] != null) {
            queryParameters['organizationIdentifier'] = requestParameters['organizationIdentifier'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/payments/upgrade`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpgradePurchaseRequestDTOToJSON(requestParameters['purchase']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PurchaseResultDTOFromJSON(jsonValue));
    }

    /**
     */
    async createCheckoutSession(requestParameters: CreateCheckoutSessionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PurchaseResultDTO> {
        const response = await this.createCheckoutSessionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createOrganizationRaw(requestParameters: CreateOrganizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrganizationDTO>> {
        if (requestParameters['organizationToCreate'] == null) {
            throw new runtime.RequiredError(
                'organizationToCreate',
                'Required parameter "organizationToCreate" was null or undefined when calling createOrganization().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/organizations2`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OrganizationCreationParamsDTOToJSON(requestParameters['organizationToCreate']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationDTOFromJSON(jsonValue));
    }

    /**
     */
    async createOrganization(requestParameters: CreateOrganizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrganizationDTO> {
        const response = await this.createOrganizationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createOrganizationInvitationsRaw(requestParameters: CreateOrganizationInvitationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrganizationInvitationsDTO>> {
        if (requestParameters['newOrganizationInvitations'] == null) {
            throw new runtime.RequiredError(
                'newOrganizationInvitations',
                'Required parameter "newOrganizationInvitations" was null or undefined when calling createOrganizationInvitations().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/invitations/organization`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewOrganizationInvitationsDTOToJSON(requestParameters['newOrganizationInvitations']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationInvitationsDTOFromJSON(jsonValue));
    }

    /**
     */
    async createOrganizationInvitations(requestParameters: CreateOrganizationInvitationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrganizationInvitationsDTO> {
        const response = await this.createOrganizationInvitationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createProjectRaw(requestParameters: CreateProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectWithRoleDTO>> {
        if (requestParameters['projectToCreate'] == null) {
            throw new runtime.RequiredError(
                'projectToCreate',
                'Required parameter "projectToCreate" was null or undefined when calling createProject().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/projects`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewProjectDTOToJSON(requestParameters['projectToCreate']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectWithRoleDTOFromJSON(jsonValue));
    }

    /**
     */
    async createProject(requestParameters: CreateProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectWithRoleDTO> {
        const response = await this.createProjectRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createProjectInvitationRaw(requestParameters: CreateProjectInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectInvitationDTO>> {
        if (requestParameters['newProjectInvitation'] == null) {
            throw new runtime.RequiredError(
                'newProjectInvitation',
                'Required parameter "newProjectInvitation" was null or undefined when calling createProjectInvitation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/invitations/project`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewProjectInvitationDTOToJSON(requestParameters['newProjectInvitation']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectInvitationDTOFromJSON(jsonValue));
    }

    /**
     */
    async createProjectInvitation(requestParameters: CreateProjectInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectInvitationDTO> {
        const response = await this.createProjectInvitationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createReservationRaw(requestParameters: CreateReservationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['newReservation'] == null) {
            throw new runtime.RequiredError(
                'newReservation',
                'Required parameter "newReservation" was null or undefined when calling createReservation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/reservations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewReservationDTOToJSON(requestParameters['newReservation']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async createReservation(requestParameters: CreateReservationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createReservationRaw(requestParameters, initOverrides);
    }

    /**
     */
    async createServiceAccountRaw(requestParameters: CreateServiceAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ServiceAccountDTO>> {
        if (requestParameters['organizationIdentifier'] == null) {
            throw new runtime.RequiredError(
                'organizationIdentifier',
                'Required parameter "organizationIdentifier" was null or undefined when calling createServiceAccount().'
            );
        }

        if (requestParameters['addToAllProjects'] == null) {
            throw new runtime.RequiredError(
                'addToAllProjects',
                'Required parameter "addToAllProjects" was null or undefined when calling createServiceAccount().'
            );
        }

        if (requestParameters['newServiceAccount'] == null) {
            throw new runtime.RequiredError(
                'newServiceAccount',
                'Required parameter "newServiceAccount" was null or undefined when calling createServiceAccount().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['organizationIdentifier'] != null) {
            queryParameters['organizationIdentifier'] = requestParameters['organizationIdentifier'];
        }

        if (requestParameters['addToAllProjects'] != null) {
            queryParameters['addToAllProjects'] = requestParameters['addToAllProjects'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/serviceAccounts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewServiceAccountDTOToJSON(requestParameters['newServiceAccount']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceAccountDTOFromJSON(jsonValue));
    }

    /**
     */
    async createServiceAccount(requestParameters: CreateServiceAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ServiceAccountDTO> {
        const response = await this.createServiceAccountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deactivateServiceAccountRaw(requestParameters: DeactivateServiceAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['organizationIdentifier'] == null) {
            throw new runtime.RequiredError(
                'organizationIdentifier',
                'Required parameter "organizationIdentifier" was null or undefined when calling deactivateServiceAccount().'
            );
        }

        if (requestParameters['serviceAccountId'] == null) {
            throw new runtime.RequiredError(
                'serviceAccountId',
                'Required parameter "serviceAccountId" was null or undefined when calling deactivateServiceAccount().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['organizationIdentifier'] != null) {
            queryParameters['organizationIdentifier'] = requestParameters['organizationIdentifier'];
        }

        if (requestParameters['serviceAccountId'] != null) {
            queryParameters['serviceAccountId'] = requestParameters['serviceAccountId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/serviceAccounts/deactivate`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deactivateServiceAccount(requestParameters: DeactivateServiceAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deactivateServiceAccountRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteOrganizationRaw(requestParameters: DeleteOrganizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['organizationId'] == null) {
            throw new runtime.RequiredError(
                'organizationId',
                'Required parameter "organizationId" was null or undefined when calling deleteOrganization().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/organizations2/{organizationId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters['organizationId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteOrganization(requestParameters: DeleteOrganizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteOrganizationRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteOrganizationMemberRaw(requestParameters: DeleteOrganizationMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['organizationIdentifier'] == null) {
            throw new runtime.RequiredError(
                'organizationIdentifier',
                'Required parameter "organizationIdentifier" was null or undefined when calling deleteOrganizationMember().'
            );
        }

        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling deleteOrganizationMember().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/organizations2/{organizationIdentifier}/members/{userId}`.replace(`{${"organizationIdentifier"}}`, encodeURIComponent(String(requestParameters['organizationIdentifier']))).replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteOrganizationMember(requestParameters: DeleteOrganizationMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteOrganizationMemberRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteProjectRaw(requestParameters: DeleteProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling deleteProject().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/projects`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteProject(requestParameters: DeleteProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteProjectRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteProjectMemberRaw(requestParameters: DeleteProjectMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling deleteProjectMember().'
            );
        }

        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling deleteProjectMember().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/projects/members/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteProjectMember(requestParameters: DeleteProjectMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteProjectMemberRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteProjectServiceAccountRaw(requestParameters: DeleteProjectServiceAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling deleteProjectServiceAccount().'
            );
        }

        if (requestParameters['serviceAccountId'] == null) {
            throw new runtime.RequiredError(
                'serviceAccountId',
                'Required parameter "serviceAccountId" was null or undefined when calling deleteProjectServiceAccount().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/projects/service_accounts/{serviceAccountId}`.replace(`{${"serviceAccountId"}}`, encodeURIComponent(String(requestParameters['serviceAccountId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteProjectServiceAccount(requestParameters: DeleteProjectServiceAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteProjectServiceAccountRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteScopedPropertyRaw(requestParameters: DeleteScopedPropertyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling deleteScopedProperty().'
            );
        }

        if (requestParameters['propertyType'] == null) {
            throw new runtime.RequiredError(
                'propertyType',
                'Required parameter "propertyType" was null or undefined when calling deleteScopedProperty().'
            );
        }

        if (requestParameters['scope'] == null) {
            throw new runtime.RequiredError(
                'scope',
                'Required parameter "scope" was null or undefined when calling deleteScopedProperty().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['key'] != null) {
            queryParameters['key'] = requestParameters['key'];
        }

        if (requestParameters['propertyType'] != null) {
            queryParameters['propertyType'] = requestParameters['propertyType'];
        }

        if (requestParameters['scope'] != null) {
            queryParameters['scope'] = requestParameters['scope'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/clients/properties`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteScopedProperty(requestParameters: DeleteScopedPropertyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteScopedPropertyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteStorageLimitRaw(requestParameters: DeleteStorageLimitRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling deleteStorageLimit().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/projects/storage-limit`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteStorageLimit(requestParameters: DeleteStorageLimitRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteStorageLimitRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteUserProfileLinkRaw(requestParameters: DeleteUserProfileLinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['link'] == null) {
            throw new runtime.RequiredError(
                'link',
                'Required parameter "link" was null or undefined when calling deleteUserProfileLink().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/userProfile/links`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: LinkDTOToJSON(requestParameters['link']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteUserProfileLink(requestParameters: DeleteUserProfileLinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteUserProfileLinkRaw(requestParameters, initOverrides);
    }

    /**
     * Detaches credit card
     */
    async detachCreditCardRaw(requestParameters: DetachCreditCardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['organizationIdentifier'] == null) {
            throw new runtime.RequiredError(
                'organizationIdentifier',
                'Required parameter "organizationIdentifier" was null or undefined when calling detachCreditCard().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/payments/{organizationIdentifier}/creditCard`.replace(`{${"organizationIdentifier"}}`, encodeURIComponent(String(requestParameters['organizationIdentifier']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Detaches credit card
     */
    async detachCreditCard(requestParameters: DetachCreditCardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.detachCreditCardRaw(requestParameters, initOverrides);
    }

    /**
     */
    async exchangeApiTokenRaw(requestParameters: ExchangeApiTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NeptuneOauthToken>> {
        if (requestParameters['xNeptuneApiToken'] == null) {
            throw new runtime.RequiredError(
                'xNeptuneApiToken',
                'Required parameter "xNeptuneApiToken" was null or undefined when calling exchangeApiToken().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['xNeptuneApiToken'] != null) {
            headerParameters['X-Neptune-Api-Token'] = String(requestParameters['xNeptuneApiToken']);
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/authorization/oauth-token`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NeptuneOauthTokenFromJSON(jsonValue));
    }

    /**
     */
    async exchangeApiToken(requestParameters: ExchangeApiTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NeptuneOauthToken> {
        const response = await this.exchangeApiTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async feedIntercomRaw(requestParameters: FeedIntercomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['intercomData'] == null) {
            throw new runtime.RequiredError(
                'intercomData',
                'Required parameter "intercomData" was null or undefined when calling feedIntercom().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/communicationData/submit`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IntercomDataDTOToJSON(requestParameters['intercomData']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async feedIntercom(requestParameters: FeedIntercomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.feedIntercomRaw(requestParameters, initOverrides);
    }

    /**
     */
    async filterOrganizationsRaw(requestParameters: FilterOrganizationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrganizationDTO>>> {
        if (requestParameters['ids'] == null) {
            throw new runtime.RequiredError(
                'ids',
                'Required parameter "ids" was null or undefined when calling filterOrganizations().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['ids'] != null) {
            queryParameters['ids'] = requestParameters['ids'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/organizations2`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrganizationDTOFromJSON));
    }

    /**
     */
    async filterOrganizations(requestParameters: FilterOrganizationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrganizationDTO>> {
        const response = await this.filterOrganizationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async generateProjectKeyRaw(requestParameters: GenerateProjectKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectKeyDTO>> {
        if (requestParameters['organizationId'] == null) {
            throw new runtime.RequiredError(
                'organizationId',
                'Required parameter "organizationId" was null or undefined when calling generateProjectKey().'
            );
        }

        if (requestParameters['projectName'] == null) {
            throw new runtime.RequiredError(
                'projectName',
                'Required parameter "projectName" was null or undefined when calling generateProjectKey().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['organizationId'] != null) {
            queryParameters['organizationId'] = requestParameters['organizationId'];
        }

        if (requestParameters['projectName'] != null) {
            queryParameters['projectName'] = requestParameters['projectName'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/projects/key`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectKeyDTOFromJSON(jsonValue));
    }

    /**
     */
    async generateProjectKey(requestParameters: GenerateProjectKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectKeyDTO> {
        const response = await this.generateProjectKeyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getApiTokenRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NeptuneApiToken>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/authorization/api-token`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NeptuneApiTokenFromJSON(jsonValue));
    }

    /**
     */
    async getApiToken(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NeptuneApiToken> {
        const response = await this.getApiTokenRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getClientConfigRaw(requestParameters: GetClientConfigRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClientConfig>> {
        const queryParameters: any = {};

        if (requestParameters['alpha'] != null) {
            queryParameters['alpha'] = requestParameters['alpha'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['xNeptuneApiToken'] != null) {
            headerParameters['X-Neptune-Api-Token'] = String(requestParameters['xNeptuneApiToken']);
        }

        const response = await this.request({
            path: `/api/backend/v1/clients/config`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientConfigFromJSON(jsonValue));
    }

    /**
     */
    async getClientConfig(requestParameters: GetClientConfigRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClientConfig> {
        const response = await this.getClientConfigRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns a Stripe Source object
     */
    async getCreditCardRaw(requestParameters: GetCreditCardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreditCardDTO>> {
        if (requestParameters['organizationIdentifier'] == null) {
            throw new runtime.RequiredError(
                'organizationIdentifier',
                'Required parameter "organizationIdentifier" was null or undefined when calling getCreditCard().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/payments/{organizationIdentifier}/creditCard`.replace(`{${"organizationIdentifier"}}`, encodeURIComponent(String(requestParameters['organizationIdentifier']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreditCardDTOFromJSON(jsonValue));
    }

    /**
     * Returns a Stripe Source object
     */
    async getCreditCard(requestParameters: GetCreditCardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreditCardDTO> {
        const response = await this.getCreditCardRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns a list of actions that backend requires the user to complete before he can start working with Neptune
     */
    async getLoginActionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoginActionsListDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/login/actions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginActionsListDTOFromJSON(jsonValue));
    }

    /**
     * Returns a list of actions that backend requires the user to complete before he can start working with Neptune
     */
    async getLoginActions(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoginActionsListDTO> {
        const response = await this.getLoginActionsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getOrganizationRaw(requestParameters: GetOrganizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrganizationDTO>> {
        if (requestParameters['organization'] == null) {
            throw new runtime.RequiredError(
                'organization',
                'Required parameter "organization" was null or undefined when calling getOrganization().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/organizations2/{organization}`.replace(`{${"organization"}}`, encodeURIComponent(String(requestParameters['organization']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationDTOFromJSON(jsonValue));
    }

    /**
     */
    async getOrganization(requestParameters: GetOrganizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrganizationDTO> {
        const response = await this.getOrganizationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOrganizationAvatarRaw(requestParameters: GetOrganizationAvatarRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['organizationName'] == null) {
            throw new runtime.RequiredError(
                'organizationName',
                'Required parameter "organizationName" was null or undefined when calling getOrganizationAvatar().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/organizations2/{organizationName}/avatar`.replace(`{${"organizationName"}}`, encodeURIComponent(String(requestParameters['organizationName']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async getOrganizationAvatar(requestParameters: GetOrganizationAvatarRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.getOrganizationAvatarRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getOrganizationInvitationRaw(requestParameters: GetOrganizationInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrganizationInvitationDTO>> {
        if (requestParameters['invitationId'] == null) {
            throw new runtime.RequiredError(
                'invitationId',
                'Required parameter "invitationId" was null or undefined when calling getOrganizationInvitation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/invitations/organization/{invitationId}`.replace(`{${"invitationId"}}`, encodeURIComponent(String(requestParameters['invitationId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationInvitationDTOFromJSON(jsonValue));
    }

    /**
     */
    async getOrganizationInvitation(requestParameters: GetOrganizationInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrganizationInvitationDTO> {
        const response = await this.getOrganizationInvitationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOrganizationLimitsRaw(requestParameters: GetOrganizationLimitsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrganizationLimitsDTO>> {
        if (requestParameters['organizationIdentifier'] == null) {
            throw new runtime.RequiredError(
                'organizationIdentifier',
                'Required parameter "organizationIdentifier" was null or undefined when calling getOrganizationLimits().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/organizations2/{organizationIdentifier}/limits`.replace(`{${"organizationIdentifier"}}`, encodeURIComponent(String(requestParameters['organizationIdentifier']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationLimitsDTOFromJSON(jsonValue));
    }

    /**
     */
    async getOrganizationLimits(requestParameters: GetOrganizationLimitsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrganizationLimitsDTO> {
        const response = await this.getOrganizationLimitsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOrganizationPricingRaw(requestParameters: GetOrganizationPricingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrganizationPricingDTO>> {
        if (requestParameters['organizationIdentifier'] == null) {
            throw new runtime.RequiredError(
                'organizationIdentifier',
                'Required parameter "organizationIdentifier" was null or undefined when calling getOrganizationPricing().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/payments/{organizationIdentifier}`.replace(`{${"organizationIdentifier"}}`, encodeURIComponent(String(requestParameters['organizationIdentifier']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationPricingDTOFromJSON(jsonValue));
    }

    /**
     */
    async getOrganizationPricing(requestParameters: GetOrganizationPricingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrganizationPricingDTO> {
        const response = await this.getOrganizationPricingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Pass-through to Stripe /v1/invoices
     */
    async getPastInvoicesRaw(requestParameters: GetPastInvoicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PastInvoicesDTO>> {
        if (requestParameters['organizationIdentifier'] == null) {
            throw new runtime.RequiredError(
                'organizationIdentifier',
                'Required parameter "organizationIdentifier" was null or undefined when calling getPastInvoices().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/payments/{organizationIdentifier}/invoices/past`.replace(`{${"organizationIdentifier"}}`, encodeURIComponent(String(requestParameters['organizationIdentifier']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PastInvoicesDTOFromJSON(jsonValue));
    }

    /**
     * Pass-through to Stripe /v1/invoices
     */
    async getPastInvoices(requestParameters: GetPastInvoicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PastInvoicesDTO> {
        const response = await this.getPastInvoicesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getProjectRaw(requestParameters: GetProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectDTO>> {
        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling getProject().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/projects/get`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectDTOFromJSON(jsonValue));
    }

    /**
     */
    async getProject(requestParameters: GetProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectDTO> {
        const response = await this.getProjectRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getProjectAvatarRaw(requestParameters: GetProjectAvatarRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['organizationName'] == null) {
            throw new runtime.RequiredError(
                'organizationName',
                'Required parameter "organizationName" was null or undefined when calling getProjectAvatar().'
            );
        }

        if (requestParameters['projectName'] == null) {
            throw new runtime.RequiredError(
                'projectName',
                'Required parameter "projectName" was null or undefined when calling getProjectAvatar().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/organizations/{organizationName}/projects/{projectName}/avatar`.replace(`{${"organizationName"}}`, encodeURIComponent(String(requestParameters['organizationName']))).replace(`{${"projectName"}}`, encodeURIComponent(String(requestParameters['projectName']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async getProjectAvatar(requestParameters: GetProjectAvatarRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.getProjectAvatarRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getProjectBackgroundRaw(requestParameters: GetProjectBackgroundRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['organizationName'] == null) {
            throw new runtime.RequiredError(
                'organizationName',
                'Required parameter "organizationName" was null or undefined when calling getProjectBackground().'
            );
        }

        if (requestParameters['projectName'] == null) {
            throw new runtime.RequiredError(
                'projectName',
                'Required parameter "projectName" was null or undefined when calling getProjectBackground().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/organizations/{organizationName}/projects/{projectName}/background`.replace(`{${"organizationName"}}`, encodeURIComponent(String(requestParameters['organizationName']))).replace(`{${"projectName"}}`, encodeURIComponent(String(requestParameters['projectName']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async getProjectBackground(requestParameters: GetProjectBackgroundRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.getProjectBackgroundRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getProjectDetailsRaw(requestParameters: GetProjectDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectWithRoleDTO>> {
        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling getProjectDetails().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/projects/details`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectWithRoleDTOFromJSON(jsonValue));
    }

    /**
     */
    async getProjectDetails(requestParameters: GetProjectDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectWithRoleDTO> {
        const response = await this.getProjectDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getProjectInvitationRaw(requestParameters: GetProjectInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectInvitationDTO>> {
        if (requestParameters['invitationId'] == null) {
            throw new runtime.RequiredError(
                'invitationId',
                'Required parameter "invitationId" was null or undefined when calling getProjectInvitation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/invitations/project/{invitationId}`.replace(`{${"invitationId"}}`, encodeURIComponent(String(requestParameters['invitationId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectInvitationDTOFromJSON(jsonValue));
    }

    /**
     */
    async getProjectInvitation(requestParameters: GetProjectInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectInvitationDTO> {
        const response = await this.getProjectInvitationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getProjectMetadataRaw(requestParameters: GetProjectMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectMetadataDTO>> {
        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling getProjectMetadata().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/projects/metadata`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectMetadataDTOFromJSON(jsonValue));
    }

    /**
     */
    async getProjectMetadata(requestParameters: GetProjectMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectMetadataDTO> {
        const response = await this.getProjectMetadataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getScopedPropertyRaw(requestParameters: GetScopedPropertyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PropertyDTO>> {
        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling getScopedProperty().'
            );
        }

        if (requestParameters['propertyType'] == null) {
            throw new runtime.RequiredError(
                'propertyType',
                'Required parameter "propertyType" was null or undefined when calling getScopedProperty().'
            );
        }

        if (requestParameters['scope'] == null) {
            throw new runtime.RequiredError(
                'scope',
                'Required parameter "scope" was null or undefined when calling getScopedProperty().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['key'] != null) {
            queryParameters['key'] = requestParameters['key'];
        }

        if (requestParameters['propertyType'] != null) {
            queryParameters['propertyType'] = requestParameters['propertyType'];
        }

        if (requestParameters['scope'] != null) {
            queryParameters['scope'] = requestParameters['scope'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/clients/properties`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PropertyDTOFromJSON(jsonValue));
    }

    /**
     */
    async getScopedProperty(requestParameters: GetScopedPropertyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PropertyDTO> {
        const response = await this.getScopedPropertyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getServiceAccountApiTokenRaw(requestParameters: GetServiceAccountApiTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NeptuneApiToken>> {
        if (requestParameters['serviceAccountId'] == null) {
            throw new runtime.RequiredError(
                'serviceAccountId',
                'Required parameter "serviceAccountId" was null or undefined when calling getServiceAccountApiToken().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['serviceAccountId'] != null) {
            queryParameters['serviceAccountId'] = requestParameters['serviceAccountId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/authorization/serviceAccounts/api-token`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NeptuneApiTokenFromJSON(jsonValue));
    }

    /**
     */
    async getServiceAccountApiToken(requestParameters: GetServiceAccountApiTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NeptuneApiToken> {
        const response = await this.getServiceAccountApiTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getSsoConfigRaw(requestParameters: GetSsoConfigRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WorkspaceConfig>> {
        if (requestParameters['workspaceName'] == null) {
            throw new runtime.RequiredError(
                'workspaceName',
                'Required parameter "workspaceName" was null or undefined when calling getSsoConfig().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['workspaceName'] != null) {
            queryParameters['workspaceName'] = requestParameters['workspaceName'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/clients/sso`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkspaceConfigFromJSON(jsonValue));
    }

    /**
     */
    async getSsoConfig(requestParameters: GetSsoConfigRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WorkspaceConfig> {
        const response = await this.getSsoConfigRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Pass-through to Stripe /v1/invoices/upcoming
     */
    async getUpcomingInvoiceRaw(requestParameters: GetUpcomingInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InvoiceDTO>> {
        if (requestParameters['organizationIdentifier'] == null) {
            throw new runtime.RequiredError(
                'organizationIdentifier',
                'Required parameter "organizationIdentifier" was null or undefined when calling getUpcomingInvoice().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/payments/{organizationIdentifier}/invoices/upcoming`.replace(`{${"organizationIdentifier"}}`, encodeURIComponent(String(requestParameters['organizationIdentifier']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InvoiceDTOFromJSON(jsonValue));
    }

    /**
     * Pass-through to Stripe /v1/invoices/upcoming
     */
    async getUpcomingInvoice(requestParameters: GetUpcomingInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InvoiceDTO> {
        const response = await this.getUpcomingInvoiceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getUserAvatarRaw(requestParameters: GetUserAvatarRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['username'] == null) {
            throw new runtime.RequiredError(
                'username',
                'Required parameter "username" was null or undefined when calling getUserAvatar().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/users/{username}/avatar`.replace(`{${"username"}}`, encodeURIComponent(String(requestParameters['username']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async getUserAvatar(requestParameters: GetUserAvatarRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.getUserAvatarRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getUserProfileRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserProfileDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/userProfile`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserProfileDTOFromJSON(jsonValue));
    }

    /**
     */
    async getUserProfile(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserProfileDTO> {
        const response = await this.getUserProfileRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getUserProfileAvatarRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/userProfile/avatar`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async getUserProfileAvatar(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.getUserProfileAvatarRaw(initOverrides);
    }

    /**
     */
    async globalConfigurationRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GlobalConfiguration>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/config`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GlobalConfigurationFromJSON(jsonValue));
    }

    /**
     */
    async globalConfiguration(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GlobalConfiguration> {
        const response = await this.globalConfigurationRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async healthzRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ComponentStatus>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/healthz`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ComponentStatusFromJSON));
    }

    /**
     */
    async healthz(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ComponentStatus>> {
        const response = await this.healthzRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async isOrganizationNameAvailableRaw(requestParameters: IsOrganizationNameAvailableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrganizationNameAvailableDTO>> {
        if (requestParameters['organizationName'] == null) {
            throw new runtime.RequiredError(
                'organizationName',
                'Required parameter "organizationName" was null or undefined when calling isOrganizationNameAvailable().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/organizations2/{organizationName}/available`.replace(`{${"organizationName"}}`, encodeURIComponent(String(requestParameters['organizationName']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationNameAvailableDTOFromJSON(jsonValue));
    }

    /**
     */
    async isOrganizationNameAvailable(requestParameters: IsOrganizationNameAvailableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrganizationNameAvailableDTO> {
        const response = await this.isOrganizationNameAvailableRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async leaveProjectRaw(requestParameters: LeaveProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling leaveProject().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/projects/leave`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async leaveProject(requestParameters: LeaveProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.leaveProjectRaw(requestParameters, initOverrides);
    }

    /**
     * Invitations are sorted from most to least recent.
     */
    async listInvitationsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InvitationListDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/invitations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InvitationListDTOFromJSON(jsonValue));
    }

    /**
     * Invitations are sorted from most to least recent.
     */
    async listInvitations(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InvitationListDTO> {
        const response = await this.listInvitationsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async listMembersRaw(requestParameters: ListMembersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserListDTO>> {
        const queryParameters: any = {};

        if (requestParameters['organizationIdentifier'] != null) {
            queryParameters['organizationIdentifier'] = requestParameters['organizationIdentifier'];
        }

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        if (requestParameters['usernamePrefix'] != null) {
            queryParameters['usernamePrefix'] = requestParameters['usernamePrefix'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/projects/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserListDTOFromJSON(jsonValue));
    }

    /**
     */
    async listMembers(requestParameters: ListMembersRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserListDTO> {
        const response = await this.listMembersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async listOrganizationMembersRaw(requestParameters: ListOrganizationMembersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrganizationMemberDTO>>> {
        if (requestParameters['organizationIdentifier'] == null) {
            throw new runtime.RequiredError(
                'organizationIdentifier',
                'Required parameter "organizationIdentifier" was null or undefined when calling listOrganizationMembers().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/organizations2/{organizationIdentifier}/members`.replace(`{${"organizationIdentifier"}}`, encodeURIComponent(String(requestParameters['organizationIdentifier']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrganizationMemberDTOFromJSON));
    }

    /**
     */
    async listOrganizationMembers(requestParameters: ListOrganizationMembersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrganizationMemberDTO>> {
        const response = await this.listOrganizationMembersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async listOrganizationProjectsRaw(requestParameters: ListOrganizationProjectsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectListDTO>> {
        if (requestParameters['organizationIdentifier'] == null) {
            throw new runtime.RequiredError(
                'organizationIdentifier',
                'Required parameter "organizationIdentifier" was null or undefined when calling listOrganizationProjects().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['organizationIdentifier'] != null) {
            queryParameters['organizationIdentifier'] = requestParameters['organizationIdentifier'];
        }

        if (requestParameters['searchTerm'] != null) {
            queryParameters['searchTerm'] = requestParameters['searchTerm'];
        }

        if (requestParameters['sortBy'] != null) {
            queryParameters['sortBy'] = requestParameters['sortBy'];
        }

        if (requestParameters['sortDirection'] != null) {
            queryParameters['sortDirection'] = requestParameters['sortDirection'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['archived'] != null) {
            queryParameters['archived'] = requestParameters['archived'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/projects/listForOrganization`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectListDTOFromJSON(jsonValue));
    }

    /**
     */
    async listOrganizationProjects(requestParameters: ListOrganizationProjectsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectListDTO> {
        const response = await this.listOrganizationProjectsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async listOrganizationsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrganizationWithRoleDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/myOrganizations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrganizationWithRoleDTOFromJSON));
    }

    /**
     */
    async listOrganizations(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrganizationWithRoleDTO>> {
        const response = await this.listOrganizationsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async listOwnersRaw(requestParameters: ListOwnersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrganizationOwnerListDTO>> {
        if (requestParameters['organizationIdentifier'] == null) {
            throw new runtime.RequiredError(
                'organizationIdentifier',
                'Required parameter "organizationIdentifier" was null or undefined when calling listOwners().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/organizations2/{organizationIdentifier}/owners`.replace(`{${"organizationIdentifier"}}`, encodeURIComponent(String(requestParameters['organizationIdentifier']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationOwnerListDTOFromJSON(jsonValue));
    }

    /**
     */
    async listOwners(requestParameters: ListOwnersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrganizationOwnerListDTO> {
        const response = await this.listOwnersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async listProjectMembersRaw(requestParameters: ListProjectMembersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ProjectMemberDTO>>> {
        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling listProjectMembers().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/projects/membersOf`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProjectMemberDTOFromJSON));
    }

    /**
     */
    async listProjectMembers(requestParameters: ListProjectMembersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ProjectMemberDTO>> {
        const response = await this.listProjectMembersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async listProjectServiceAccountsRaw(requestParameters: ListProjectServiceAccountsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ServiceAccountWithRoleDTO>>> {
        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling listProjectServiceAccounts().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/projects/service_accounts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ServiceAccountWithRoleDTOFromJSON));
    }

    /**
     */
    async listProjectServiceAccounts(requestParameters: ListProjectServiceAccountsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ServiceAccountWithRoleDTO>> {
        const response = await this.listProjectServiceAccountsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async listProjectsRaw(requestParameters: ListProjectsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectListDTO>> {
        const queryParameters: any = {};

        if (requestParameters['organizationIdentifier'] != null) {
            queryParameters['organizationIdentifier'] = requestParameters['organizationIdentifier'];
        }

        if (requestParameters['userRelation'] != null) {
            queryParameters['userRelation'] = requestParameters['userRelation'];
        }

        if (requestParameters['searchTerm'] != null) {
            queryParameters['searchTerm'] = requestParameters['searchTerm'];
        }

        if (requestParameters['sortBy'] != null) {
            queryParameters['sortBy'] = requestParameters['sortBy'];
        }

        if (requestParameters['sortDirection'] != null) {
            queryParameters['sortDirection'] = requestParameters['sortDirection'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/projects`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectListDTOFromJSON(jsonValue));
    }

    /**
     */
    async listProjects(requestParameters: ListProjectsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectListDTO> {
        const response = await this.listProjectsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * @deprecated
     */
    async listProjectsMembersRaw(requestParameters: ListProjectsMembersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ProjectMembersDTO>>> {
        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling listProjectsMembers().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        if (requestParameters['includeInvitations'] != null) {
            queryParameters['includeInvitations'] = requestParameters['includeInvitations'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/projects/members`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProjectMembersDTOFromJSON));
    }

    /**
     * @deprecated
     */
    async listProjectsMembers(requestParameters: ListProjectsMembersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ProjectMembersDTO>> {
        const response = await this.listProjectsMembersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async listProjectsMembersPostRaw(requestParameters: ListProjectsMembersPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ProjectMembersDTO>>> {
        if (requestParameters['projectIdentifiers'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifiers',
                'Required parameter "projectIdentifiers" was null or undefined when calling listProjectsMembersPost().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['includeInvitations'] != null) {
            queryParameters['includeInvitations'] = requestParameters['includeInvitations'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/projects/membersList`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['projectIdentifiers'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProjectMembersDTOFromJSON));
    }

    /**
     */
    async listProjectsMembersPost(requestParameters: ListProjectsMembersPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ProjectMembersDTO>> {
        const response = await this.listProjectsMembersPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async listPublicProjectsRaw(requestParameters: ListPublicProjectsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectListDTO>> {
        const queryParameters: any = {};

        if (requestParameters['searchTerm'] != null) {
            queryParameters['searchTerm'] = requestParameters['searchTerm'];
        }

        if (requestParameters['sortBy'] != null) {
            queryParameters['sortBy'] = requestParameters['sortBy'];
        }

        if (requestParameters['sortDirection'] != null) {
            queryParameters['sortDirection'] = requestParameters['sortDirection'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/projects/listPublic`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectListDTOFromJSON(jsonValue));
    }

    /**
     */
    async listPublicProjects(requestParameters: ListPublicProjectsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectListDTO> {
        const response = await this.listPublicProjectsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async listRolesForUserRaw(requestParameters: ListRolesForUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectListDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/projects/listRolesForUser`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['projectIdentifiers'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectListDTOFromJSON(jsonValue));
    }

    /**
     */
    async listRolesForUser(requestParameters: ListRolesForUserRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectListDTO> {
        const response = await this.listRolesForUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async listScopedPropertiesRaw(requestParameters: ListScopedPropertiesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PropertyListDTO>> {
        if (requestParameters['propertyType'] == null) {
            throw new runtime.RequiredError(
                'propertyType',
                'Required parameter "propertyType" was null or undefined when calling listScopedProperties().'
            );
        }

        if (requestParameters['scope'] == null) {
            throw new runtime.RequiredError(
                'scope',
                'Required parameter "scope" was null or undefined when calling listScopedProperties().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['propertyType'] != null) {
            queryParameters['propertyType'] = requestParameters['propertyType'];
        }

        if (requestParameters['scope'] != null) {
            queryParameters['scope'] = requestParameters['scope'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/clients/properties/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PropertyListDTOFromJSON(jsonValue));
    }

    /**
     */
    async listScopedProperties(requestParameters: ListScopedPropertiesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PropertyListDTO> {
        const response = await this.listScopedPropertiesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async listServiceAccountsRaw(requestParameters: ListServiceAccountsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ServiceAccountDTO>>> {
        if (requestParameters['organizationIdentifier'] == null) {
            throw new runtime.RequiredError(
                'organizationIdentifier',
                'Required parameter "organizationIdentifier" was null or undefined when calling listServiceAccounts().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['organizationIdentifier'] != null) {
            queryParameters['organizationIdentifier'] = requestParameters['organizationIdentifier'];
        }

        if (requestParameters['deactivated'] != null) {
            queryParameters['deactivated'] = requestParameters['deactivated'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/serviceAccounts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ServiceAccountDTOFromJSON));
    }

    /**
     */
    async listServiceAccounts(requestParameters: ListServiceAccountsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ServiceAccountDTO>> {
        const response = await this.listServiceAccountsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async listUserProjectsRaw(requestParameters: ListUserProjectsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectListDTO>> {
        if (requestParameters['viewedUsername'] == null) {
            throw new runtime.RequiredError(
                'viewedUsername',
                'Required parameter "viewedUsername" was null or undefined when calling listUserProjects().'
            );
        }

        if (requestParameters['projectsForUserMode'] == null) {
            throw new runtime.RequiredError(
                'projectsForUserMode',
                'Required parameter "projectsForUserMode" was null or undefined when calling listUserProjects().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['viewedUsername'] != null) {
            queryParameters['viewedUsername'] = requestParameters['viewedUsername'];
        }

        if (requestParameters['projectsForUserMode'] != null) {
            queryParameters['projectsForUserMode'] = requestParameters['projectsForUserMode'];
        }

        if (requestParameters['organizationIdentifier'] != null) {
            queryParameters['organizationIdentifier'] = requestParameters['organizationIdentifier'];
        }

        if (requestParameters['userRelation'] != null) {
            queryParameters['userRelation'] = requestParameters['userRelation'];
        }

        if (requestParameters['searchTerm'] != null) {
            queryParameters['searchTerm'] = requestParameters['searchTerm'];
        }

        if (requestParameters['sortBy'] != null) {
            queryParameters['sortBy'] = requestParameters['sortBy'];
        }

        if (requestParameters['sortDirection'] != null) {
            queryParameters['sortDirection'] = requestParameters['sortDirection'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['archived'] != null) {
            queryParameters['archived'] = requestParameters['archived'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/projects/listForUser`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectListDTOFromJSON(jsonValue));
    }

    /**
     */
    async listUserProjects(requestParameters: ListUserProjectsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectListDTO> {
        const response = await this.listUserProjectsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async listUsersRaw(requestParameters: ListUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PublicUserProfileDTO>>> {
        if (requestParameters['username'] == null) {
            throw new runtime.RequiredError(
                'username',
                'Required parameter "username" was null or undefined when calling listUsers().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['username'] != null) {
            queryParameters['username'] = requestParameters['username'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PublicUserProfileDTOFromJSON));
    }

    /**
     */
    async listUsers(requestParameters: ListUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PublicUserProfileDTO>> {
        const response = await this.listUsersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async monitoringTimePackagesRaw(requestParameters: MonitoringTimePackagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MonitoringTimePackageListDTO>> {
        if (requestParameters['organizationIdentifier'] == null) {
            throw new runtime.RequiredError(
                'organizationIdentifier',
                'Required parameter "organizationIdentifier" was null or undefined when calling monitoringTimePackages().'
            );
        }

        if (requestParameters['limit'] == null) {
            throw new runtime.RequiredError(
                'limit',
                'Required parameter "limit" was null or undefined when calling monitoringTimePackages().'
            );
        }

        if (requestParameters['offset'] == null) {
            throw new runtime.RequiredError(
                'offset',
                'Required parameter "offset" was null or undefined when calling monitoringTimePackages().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['organizationIdentifier'] != null) {
            queryParameters['organizationIdentifier'] = requestParameters['organizationIdentifier'];
        }

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        if (requestParameters['dateMin'] != null) {
            queryParameters['dateMin'] = (requestParameters['dateMin'] as any).toISOString();
        }

        if (requestParameters['dateMax'] != null) {
            queryParameters['dateMax'] = (requestParameters['dateMax'] as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/resources/packages`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitoringTimePackageListDTOFromJSON(jsonValue));
    }

    /**
     */
    async monitoringTimePackages(requestParameters: MonitoringTimePackagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MonitoringTimePackageListDTO> {
        const response = await this.monitoringTimePackagesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async monitoringTimeStatsRaw(requestParameters: MonitoringTimeStatsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MonitoringTimeStatsDTO>> {
        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling monitoringTimeStats().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/resources/stats`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitoringTimeStatsDTOFromJSON(jsonValue));
    }

    /**
     */
    async monitoringTimeStats(requestParameters: MonitoringTimeStatsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MonitoringTimeStatsDTO> {
        const response = await this.monitoringTimeStatsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async monitoringTimeStatusRaw(requestParameters: MonitoringTimeStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MonitoringTimeStatusDTO>> {
        if (requestParameters['organizationIdentifier'] == null) {
            throw new runtime.RequiredError(
                'organizationIdentifier',
                'Required parameter "organizationIdentifier" was null or undefined when calling monitoringTimeStatus().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['organizationIdentifier'] != null) {
            queryParameters['organizationIdentifier'] = requestParameters['organizationIdentifier'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/resources/status`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitoringTimeStatusDTOFromJSON(jsonValue));
    }

    /**
     */
    async monitoringTimeStatus(requestParameters: MonitoringTimeStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MonitoringTimeStatusDTO> {
        const response = await this.monitoringTimeStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async monitoringTimeTopProjects30DaysRaw(requestParameters: MonitoringTimeTopProjects30DaysRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectUsageListDTO>> {
        if (requestParameters['organizationIdentifier'] == null) {
            throw new runtime.RequiredError(
                'organizationIdentifier',
                'Required parameter "organizationIdentifier" was null or undefined when calling monitoringTimeTopProjects30Days().'
            );
        }

        if (requestParameters['sortBy'] == null) {
            throw new runtime.RequiredError(
                'sortBy',
                'Required parameter "sortBy" was null or undefined when calling monitoringTimeTopProjects30Days().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['organizationIdentifier'] != null) {
            queryParameters['organizationIdentifier'] = requestParameters['organizationIdentifier'];
        }

        if (requestParameters['sortBy'] != null) {
            queryParameters['sortBy'] = requestParameters['sortBy'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/resources/topProjects30Days`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectUsageListDTOFromJSON(jsonValue));
    }

    /**
     */
    async monitoringTimeTopProjects30Days(requestParameters: MonitoringTimeTopProjects30DaysRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectUsageListDTO> {
        const response = await this.monitoringTimeTopProjects30DaysRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async monitoringTimeUsageRaw(requestParameters: MonitoringTimeUsageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MonitoringTimeUsageDTO>> {
        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling monitoringTimeUsage().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/resources/usage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitoringTimeUsageDTOFromJSON(jsonValue));
    }

    /**
     */
    async monitoringTimeUsage(requestParameters: MonitoringTimeUsageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MonitoringTimeUsageDTO> {
        const response = await this.monitoringTimeUsageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async organizationMonitoringTimeUsageRaw(requestParameters: OrganizationMonitoringTimeUsageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MonitoringTimeAndPackageHistoryDTO>> {
        if (requestParameters['organizationIdentifier'] == null) {
            throw new runtime.RequiredError(
                'organizationIdentifier',
                'Required parameter "organizationIdentifier" was null or undefined when calling organizationMonitoringTimeUsage().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['organizationIdentifier'] != null) {
            queryParameters['organizationIdentifier'] = requestParameters['organizationIdentifier'];
        }

        if (requestParameters['dateMin'] != null) {
            queryParameters['dateMin'] = (requestParameters['dateMin'] as any).toISOString();
        }

        if (requestParameters['dateMax'] != null) {
            queryParameters['dateMax'] = (requestParameters['dateMax'] as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/resources/organizationUsageHistory`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitoringTimeAndPackageHistoryDTOFromJSON(jsonValue));
    }

    /**
     */
    async organizationMonitoringTimeUsage(requestParameters: OrganizationMonitoringTimeUsageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MonitoringTimeAndPackageHistoryDTO> {
        const response = await this.organizationMonitoringTimeUsageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async projectMonitoringTimeUsageRaw(requestParameters: ProjectMonitoringTimeUsageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MonitoringTimeHistoryDTO>> {
        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling projectMonitoringTimeUsage().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        if (requestParameters['dateMin'] != null) {
            queryParameters['dateMin'] = (requestParameters['dateMin'] as any).toISOString();
        }

        if (requestParameters['dateMax'] != null) {
            queryParameters['dateMax'] = (requestParameters['dateMax'] as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/resources/projectUsageHistory`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitoringTimeHistoryDTOFromJSON(jsonValue));
    }

    /**
     */
    async projectMonitoringTimeUsage(requestParameters: ProjectMonitoringTimeUsageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MonitoringTimeHistoryDTO> {
        const response = await this.projectMonitoringTimeUsageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async requestOnpremRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/payments/request/onprem`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async requestOnprem(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.requestOnpremRaw(initOverrides);
    }

    /**
     */
    async requestTeamTrialRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/payments/request/team-trial`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async requestTeamTrial(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.requestTeamTrialRaw(initOverrides);
    }

    /**
     */
    async resendExistingOrganizationInvitationRaw(requestParameters: ResendExistingOrganizationInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['invitationId'] == null) {
            throw new runtime.RequiredError(
                'invitationId',
                'Required parameter "invitationId" was null or undefined when calling resendExistingOrganizationInvitation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/invitations/organization/{invitationId}/resend`.replace(`{${"invitationId"}}`, encodeURIComponent(String(requestParameters['invitationId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async resendExistingOrganizationInvitation(requestParameters: ResendExistingOrganizationInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.resendExistingOrganizationInvitationRaw(requestParameters, initOverrides);
    }

    /**
     */
    async resendOrganizationInvitationRaw(requestParameters: ResendOrganizationInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['resendOrganizationInvitation'] == null) {
            throw new runtime.RequiredError(
                'resendOrganizationInvitation',
                'Required parameter "resendOrganizationInvitation" was null or undefined when calling resendOrganizationInvitation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/invitations/organization/resend`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResendOrganizationInvitationDTOToJSON(requestParameters['resendOrganizationInvitation']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async resendOrganizationInvitation(requestParameters: ResendOrganizationInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.resendOrganizationInvitationRaw(requestParameters, initOverrides);
    }

    /**
     */
    async resendProjectInvitationRaw(requestParameters: ResendProjectInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['resendProjectInvitation'] == null) {
            throw new runtime.RequiredError(
                'resendProjectInvitation',
                'Required parameter "resendProjectInvitation" was null or undefined when calling resendProjectInvitation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/invitations/project/resend`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResendProjectInvitationDTOToJSON(requestParameters['resendProjectInvitation']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async resendProjectInvitation(requestParameters: ResendProjectInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.resendProjectInvitationRaw(requestParameters, initOverrides);
    }

    /**
     */
    async revokeApiTokenRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/authorization/api-token`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async revokeApiToken(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.revokeApiTokenRaw(initOverrides);
    }

    /**
     */
    async revokeOrganizationInvitationRaw(requestParameters: RevokeOrganizationInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['invitationId'] == null) {
            throw new runtime.RequiredError(
                'invitationId',
                'Required parameter "invitationId" was null or undefined when calling revokeOrganizationInvitation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/invitations/organization/{invitationId}`.replace(`{${"invitationId"}}`, encodeURIComponent(String(requestParameters['invitationId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async revokeOrganizationInvitation(requestParameters: RevokeOrganizationInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.revokeOrganizationInvitationRaw(requestParameters, initOverrides);
    }

    /**
     */
    async revokeProjectInvitationRaw(requestParameters: RevokeProjectInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['invitationId'] == null) {
            throw new runtime.RequiredError(
                'invitationId',
                'Required parameter "invitationId" was null or undefined when calling revokeProjectInvitation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/invitations/project/{invitationId}`.replace(`{${"invitationId"}}`, encodeURIComponent(String(requestParameters['invitationId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async revokeProjectInvitation(requestParameters: RevokeProjectInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.revokeProjectInvitationRaw(requestParameters, initOverrides);
    }

    /**
     */
    async revokeServiceAccountApiTokenRaw(requestParameters: RevokeServiceAccountApiTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['serviceAccountId'] == null) {
            throw new runtime.RequiredError(
                'serviceAccountId',
                'Required parameter "serviceAccountId" was null or undefined when calling revokeServiceAccountApiToken().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['serviceAccountId'] != null) {
            queryParameters['serviceAccountId'] = requestParameters['serviceAccountId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/authorization/serviceAccounts/api-token`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async revokeServiceAccountApiToken(requestParameters: RevokeServiceAccountApiTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.revokeServiceAccountApiTokenRaw(requestParameters, initOverrides);
    }

    /**
     * Processes the survey. Sending {} means that user declined survey and action is removed
     */
    async sendRegistrationSurveyRaw(requestParameters: SendRegistrationSurveyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['survey'] == null) {
            throw new runtime.RequiredError(
                'survey',
                'Required parameter "survey" was null or undefined when calling sendRegistrationSurvey().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/login/survey`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RegistrationSurveyDTOToJSON(requestParameters['survey']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Processes the survey. Sending {} means that user declined survey and action is removed
     */
    async sendRegistrationSurvey(requestParameters: SendRegistrationSurveyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sendRegistrationSurveyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async setScopedPropertyRaw(requestParameters: SetScopedPropertyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['propertyType'] == null) {
            throw new runtime.RequiredError(
                'propertyType',
                'Required parameter "propertyType" was null or undefined when calling setScopedProperty().'
            );
        }

        if (requestParameters['scope'] == null) {
            throw new runtime.RequiredError(
                'scope',
                'Required parameter "scope" was null or undefined when calling setScopedProperty().'
            );
        }

        if (requestParameters['propertyBody'] == null) {
            throw new runtime.RequiredError(
                'propertyBody',
                'Required parameter "propertyBody" was null or undefined when calling setScopedProperty().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['propertyType'] != null) {
            queryParameters['propertyType'] = requestParameters['propertyType'];
        }

        if (requestParameters['scope'] != null) {
            queryParameters['scope'] = requestParameters['scope'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/clients/properties`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PropertyDTOToJSON(requestParameters['propertyBody']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async setScopedProperty(requestParameters: SetScopedPropertyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.setScopedPropertyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async setStorageLimitRaw(requestParameters: SetStorageLimitRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectStorageLimitTraitDTO>> {
        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling setStorageLimit().'
            );
        }

        if (requestParameters['storageLimit'] == null) {
            throw new runtime.RequiredError(
                'storageLimit',
                'Required parameter "storageLimit" was null or undefined when calling setStorageLimit().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/projects/storage-limit`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetProjectStorageLimitDTOToJSON(requestParameters['storageLimit']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectStorageLimitTraitDTOFromJSON(jsonValue));
    }

    /**
     */
    async setStorageLimit(requestParameters: SetStorageLimitRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectStorageLimitTraitDTO> {
        const response = await this.setStorageLimitRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Sets the user matadata
     */
    async setUserMetadataRaw(requestParameters: SetUserMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['metadata'] == null) {
            throw new runtime.RequiredError(
                'metadata',
                'Required parameter "metadata" was null or undefined when calling setUserMetadata().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/login/metadata`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserMetadataDTOToJSON(requestParameters['metadata']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Sets the user matadata
     */
    async setUserMetadata(requestParameters: SetUserMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.setUserMetadataRaw(requestParameters, initOverrides);
    }

    /**
     *  Sets the username as per param. Can be called once, subsequent calls will result in 403 error. Setting to an invalid username will result in 400 error. Setting to an unavailable username will result in 409 error. 
     */
    async setUsernameRaw(requestParameters: SetUsernameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['username'] == null) {
            throw new runtime.RequiredError(
                'username',
                'Required parameter "username" was null or undefined when calling setUsername().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['username'] != null) {
            queryParameters['username'] = requestParameters['username'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/login/username`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     *  Sets the username as per param. Can be called once, subsequent calls will result in 403 error. Setting to an invalid username will result in 400 error. Setting to an unavailable username will result in 409 error. 
     */
    async setUsername(requestParameters: SetUsernameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.setUsernameRaw(requestParameters, initOverrides);
    }

    /**
     */
    async startupRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ComponentStatus>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/startup`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ComponentStatusFromJSON));
    }

    /**
     */
    async startup(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ComponentStatus>> {
        const response = await this.startupRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async statusGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/status`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async statusGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.statusGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async storageUsageRaw(requestParameters: StorageUsageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StorageUsage>> {
        const queryParameters: any = {};

        if (requestParameters['organizationIdentifier'] != null) {
            queryParameters['organizationIdentifier'] = requestParameters['organizationIdentifier'];
        }

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/storage/usage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StorageUsageFromJSON(jsonValue));
    }

    /**
     */
    async storageUsage(requestParameters: StorageUsageRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StorageUsage> {
        const response = await this.storageUsageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateActiveProjectsQuotaRaw(requestParameters: UpdateActiveProjectsQuotaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['organizationIdentifier'] == null) {
            throw new runtime.RequiredError(
                'organizationIdentifier',
                'Required parameter "organizationIdentifier" was null or undefined when calling updateActiveProjectsQuota().'
            );
        }

        if (requestParameters['quota'] == null) {
            throw new runtime.RequiredError(
                'quota',
                'Required parameter "quota" was null or undefined when calling updateActiveProjectsQuota().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['quota'] != null) {
            queryParameters['quota'] = requestParameters['quota'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/organizations2/{organizationIdentifier}/activeProjectsQuota`.replace(`{${"organizationIdentifier"}}`, encodeURIComponent(String(requestParameters['organizationIdentifier']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateActiveProjectsQuota(requestParameters: UpdateActiveProjectsQuotaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateActiveProjectsQuotaRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateLastViewedRaw(requestParameters: UpdateLastViewedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['projectId'] == null) {
            throw new runtime.RequiredError(
                'projectId',
                'Required parameter "projectId" was null or undefined when calling updateLastViewed().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['projectId'] != null) {
            queryParameters['projectId'] = requestParameters['projectId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/projects/updateLastViewed`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateLastViewed(requestParameters: UpdateLastViewedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateLastViewedRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateOrganizationRaw(requestParameters: UpdateOrganizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrganizationDTO>> {
        if (requestParameters['organizationId'] == null) {
            throw new runtime.RequiredError(
                'organizationId',
                'Required parameter "organizationId" was null or undefined when calling updateOrganization().'
            );
        }

        if (requestParameters['organizationToUpdate'] == null) {
            throw new runtime.RequiredError(
                'organizationToUpdate',
                'Required parameter "organizationToUpdate" was null or undefined when calling updateOrganization().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/organizations2/{organizationId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters['organizationId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OrganizationUpdateDTOToJSON(requestParameters['organizationToUpdate']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationDTOFromJSON(jsonValue));
    }

    /**
     */
    async updateOrganization(requestParameters: UpdateOrganizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrganizationDTO> {
        const response = await this.updateOrganizationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateOrganizationAvatarRaw(requestParameters: UpdateOrganizationAvatarRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LinkDTO>> {
        if (requestParameters['organizationId'] == null) {
            throw new runtime.RequiredError(
                'organizationId',
                'Required parameter "organizationId" was null or undefined when calling updateOrganizationAvatar().'
            );
        }

        if (requestParameters['avatarFile'] == null) {
            throw new runtime.RequiredError(
                'avatarFile',
                'Required parameter "avatarFile" was null or undefined when calling updateOrganizationAvatar().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters['avatarFile'] != null) {
            formParams.append('avatarFile', requestParameters['avatarFile'] as any);
        }

        const response = await this.request({
            path: `/api/backend/v1/organizations2/{organizationId}/avatar`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters['organizationId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LinkDTOFromJSON(jsonValue));
    }

    /**
     */
    async updateOrganizationAvatar(requestParameters: UpdateOrganizationAvatarRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LinkDTO> {
        const response = await this.updateOrganizationAvatarRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateOrganizationInvitationRaw(requestParameters: UpdateOrganizationInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrganizationInvitationDTO>> {
        if (requestParameters['invitationId'] == null) {
            throw new runtime.RequiredError(
                'invitationId',
                'Required parameter "invitationId" was null or undefined when calling updateOrganizationInvitation().'
            );
        }

        if (requestParameters['update'] == null) {
            throw new runtime.RequiredError(
                'update',
                'Required parameter "update" was null or undefined when calling updateOrganizationInvitation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/invitations/organization/{invitationId}`.replace(`{${"invitationId"}}`, encodeURIComponent(String(requestParameters['invitationId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OrganizationInvitationUpdateDTOToJSON(requestParameters['update']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationInvitationDTOFromJSON(jsonValue));
    }

    /**
     */
    async updateOrganizationInvitation(requestParameters: UpdateOrganizationInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrganizationInvitationDTO> {
        const response = await this.updateOrganizationInvitationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateOrganizationMemberRaw(requestParameters: UpdateOrganizationMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrganizationMemberDTO>> {
        if (requestParameters['organizationIdentifier'] == null) {
            throw new runtime.RequiredError(
                'organizationIdentifier',
                'Required parameter "organizationIdentifier" was null or undefined when calling updateOrganizationMember().'
            );
        }

        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling updateOrganizationMember().'
            );
        }

        if (requestParameters['member'] == null) {
            throw new runtime.RequiredError(
                'member',
                'Required parameter "member" was null or undefined when calling updateOrganizationMember().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/organizations2/{organizationIdentifier}/members/{userId}`.replace(`{${"organizationIdentifier"}}`, encodeURIComponent(String(requestParameters['organizationIdentifier']))).replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: OrganizationMemberUpdateDTOToJSON(requestParameters['member']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationMemberDTOFromJSON(jsonValue));
    }

    /**
     */
    async updateOrganizationMember(requestParameters: UpdateOrganizationMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrganizationMemberDTO> {
        const response = await this.updateOrganizationMemberRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateProjectRaw(requestParameters: UpdateProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectWithRoleDTO>> {
        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling updateProject().'
            );
        }

        if (requestParameters['projectToUpdate'] == null) {
            throw new runtime.RequiredError(
                'projectToUpdate',
                'Required parameter "projectToUpdate" was null or undefined when calling updateProject().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/projects`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectUpdateDTOToJSON(requestParameters['projectToUpdate']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectWithRoleDTOFromJSON(jsonValue));
    }

    /**
     */
    async updateProject(requestParameters: UpdateProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectWithRoleDTO> {
        const response = await this.updateProjectRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateProjectAvatarRaw(requestParameters: UpdateProjectAvatarRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Link>> {
        if (requestParameters['projectId'] == null) {
            throw new runtime.RequiredError(
                'projectId',
                'Required parameter "projectId" was null or undefined when calling updateProjectAvatar().'
            );
        }

        if (requestParameters['avatarFile'] == null) {
            throw new runtime.RequiredError(
                'avatarFile',
                'Required parameter "avatarFile" was null or undefined when calling updateProjectAvatar().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters['avatarFile'] != null) {
            formParams.append('avatarFile', requestParameters['avatarFile'] as any);
        }

        const response = await this.request({
            path: `/api/backend/v1/projects1/{projectId}/avatar`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters['projectId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LinkFromJSON(jsonValue));
    }

    /**
     */
    async updateProjectAvatar(requestParameters: UpdateProjectAvatarRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Link> {
        const response = await this.updateProjectAvatarRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateProjectBackgroundRaw(requestParameters: UpdateProjectBackgroundRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Link>> {
        if (requestParameters['projectId'] == null) {
            throw new runtime.RequiredError(
                'projectId',
                'Required parameter "projectId" was null or undefined when calling updateProjectBackground().'
            );
        }

        if (requestParameters['backgroundFile'] == null) {
            throw new runtime.RequiredError(
                'backgroundFile',
                'Required parameter "backgroundFile" was null or undefined when calling updateProjectBackground().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters['backgroundFile'] != null) {
            formParams.append('backgroundFile', requestParameters['backgroundFile'] as any);
        }

        const response = await this.request({
            path: `/api/backend/v1/projects1/{projectId}/background`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters['projectId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LinkFromJSON(jsonValue));
    }

    /**
     */
    async updateProjectBackground(requestParameters: UpdateProjectBackgroundRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Link> {
        const response = await this.updateProjectBackgroundRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateProjectInvitationRaw(requestParameters: UpdateProjectInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectInvitationDTO>> {
        if (requestParameters['invitationId'] == null) {
            throw new runtime.RequiredError(
                'invitationId',
                'Required parameter "invitationId" was null or undefined when calling updateProjectInvitation().'
            );
        }

        if (requestParameters['update'] == null) {
            throw new runtime.RequiredError(
                'update',
                'Required parameter "update" was null or undefined when calling updateProjectInvitation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/invitations/project/{invitationId}`.replace(`{${"invitationId"}}`, encodeURIComponent(String(requestParameters['invitationId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectInvitationUpdateDTOToJSON(requestParameters['update']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectInvitationDTOFromJSON(jsonValue));
    }

    /**
     */
    async updateProjectInvitation(requestParameters: UpdateProjectInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectInvitationDTO> {
        const response = await this.updateProjectInvitationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateProjectMemberRaw(requestParameters: UpdateProjectMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectMemberDTO>> {
        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling updateProjectMember().'
            );
        }

        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling updateProjectMember().'
            );
        }

        if (requestParameters['member'] == null) {
            throw new runtime.RequiredError(
                'member',
                'Required parameter "member" was null or undefined when calling updateProjectMember().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/projects/members/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectMemberUpdateDTOToJSON(requestParameters['member']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectMemberDTOFromJSON(jsonValue));
    }

    /**
     */
    async updateProjectMember(requestParameters: UpdateProjectMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectMemberDTO> {
        const response = await this.updateProjectMemberRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateProjectServiceAccountRaw(requestParameters: UpdateProjectServiceAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ServiceAccountWithRoleDTO>> {
        if (requestParameters['projectIdentifier'] == null) {
            throw new runtime.RequiredError(
                'projectIdentifier',
                'Required parameter "projectIdentifier" was null or undefined when calling updateProjectServiceAccount().'
            );
        }

        if (requestParameters['serviceAccountId'] == null) {
            throw new runtime.RequiredError(
                'serviceAccountId',
                'Required parameter "serviceAccountId" was null or undefined when calling updateProjectServiceAccount().'
            );
        }

        if (requestParameters['member'] == null) {
            throw new runtime.RequiredError(
                'member',
                'Required parameter "member" was null or undefined when calling updateProjectServiceAccount().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['projectIdentifier'] != null) {
            queryParameters['projectIdentifier'] = requestParameters['projectIdentifier'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/projects/service_accounts/{serviceAccountId}`.replace(`{${"serviceAccountId"}}`, encodeURIComponent(String(requestParameters['serviceAccountId']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectServiceAccountsUpdateDTOToJSON(requestParameters['member']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceAccountWithRoleDTOFromJSON(jsonValue));
    }

    /**
     */
    async updateProjectServiceAccount(requestParameters: UpdateProjectServiceAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ServiceAccountWithRoleDTO> {
        const response = await this.updateProjectServiceAccountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateUserProfileRaw(requestParameters: UpdateUserProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserProfileDTO>> {
        if (requestParameters['userProfileUpdate'] == null) {
            throw new runtime.RequiredError(
                'userProfileUpdate',
                'Required parameter "userProfileUpdate" was null or undefined when calling updateUserProfile().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/userProfile`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UserProfileUpdateDTOToJSON(requestParameters['userProfileUpdate']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserProfileDTOFromJSON(jsonValue));
    }

    /**
     */
    async updateUserProfile(requestParameters: UpdateUserProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserProfileDTO> {
        const response = await this.updateUserProfileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateUserProfileAvatarRaw(requestParameters: UpdateUserProfileAvatarRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LinkDTO>> {
        if (requestParameters['avatarFile'] == null) {
            throw new runtime.RequiredError(
                'avatarFile',
                'Required parameter "avatarFile" was null or undefined when calling updateUserProfileAvatar().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters['avatarFile'] != null) {
            formParams.append('avatarFile', requestParameters['avatarFile'] as any);
        }

        const response = await this.request({
            path: `/api/backend/v1/userProfile/avatar`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LinkDTOFromJSON(jsonValue));
    }

    /**
     */
    async updateUserProfileAvatar(requestParameters: UpdateUserProfileAvatarRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LinkDTO> {
        const response = await this.updateUserProfileAvatarRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateVisibilityRestrictionsRaw(requestParameters: UpdateVisibilityRestrictionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['organizationIdentifier'] == null) {
            throw new runtime.RequiredError(
                'organizationIdentifier',
                'Required parameter "organizationIdentifier" was null or undefined when calling updateVisibilityRestrictions().'
            );
        }

        if (requestParameters['updateRequest'] == null) {
            throw new runtime.RequiredError(
                'updateRequest',
                'Required parameter "updateRequest" was null or undefined when calling updateVisibilityRestrictions().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/organizations2/{organizationIdentifier}/updateVisibilityRestrictions`.replace(`{${"organizationIdentifier"}}`, encodeURIComponent(String(requestParameters['organizationIdentifier']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectVisibilityRestrictedUpdateRequestDTOToJSON(requestParameters['updateRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateVisibilityRestrictions(requestParameters: UpdateVisibilityRestrictionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateVisibilityRestrictionsRaw(requestParameters, initOverrides);
    }

    /**
     */
    async validateUsernameRaw(requestParameters: ValidateUsernameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UsernameValidationStatusDTO>> {
        if (requestParameters['username'] == null) {
            throw new runtime.RequiredError(
                'username',
                'Required parameter "username" was null or undefined when calling validateUsername().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['username'] != null) {
            queryParameters['username'] = requestParameters['username'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/backend/v1/login/username/validate`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UsernameValidationStatusDTOFromJSON(jsonValue));
    }

    /**
     */
    async validateUsername(requestParameters: ValidateUsernameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UsernameValidationStatusDTO> {
        const response = await this.validateUsernameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async verifyProjectKeyRaw(requestParameters: VerifyProjectKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectKeyAvailabilityDTO>> {
        if (requestParameters['organizationIdentifier'] == null) {
            throw new runtime.RequiredError(
                'organizationIdentifier',
                'Required parameter "organizationIdentifier" was null or undefined when calling verifyProjectKey().'
            );
        }

        if (requestParameters['projectKey'] == null) {
            throw new runtime.RequiredError(
                'projectKey',
                'Required parameter "projectKey" was null or undefined when calling verifyProjectKey().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['organizationIdentifier'] != null) {
            queryParameters['organizationIdentifier'] = requestParameters['organizationIdentifier'];
        }

        if (requestParameters['projectKey'] != null) {
            queryParameters['projectKey'] = requestParameters['projectKey'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/projects/verify-project-key`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectKeyAvailabilityDTOFromJSON(jsonValue));
    }

    /**
     */
    async verifyProjectKey(requestParameters: VerifyProjectKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectKeyAvailabilityDTO> {
        const response = await this.verifyProjectKeyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async workspaceStatusRaw(requestParameters: WorkspaceStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WorkspaceStatusDTO>> {
        if (requestParameters['organizationIdentifier'] == null) {
            throw new runtime.RequiredError(
                'organizationIdentifier',
                'Required parameter "organizationIdentifier" was null or undefined when calling workspaceStatus().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/api/backend/v1/payments/{organizationIdentifier}/status`.replace(`{${"organizationIdentifier"}}`, encodeURIComponent(String(requestParameters['organizationIdentifier']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkspaceStatusDTOFromJSON(jsonValue));
    }

    /**
     */
    async workspaceStatus(requestParameters: WorkspaceStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WorkspaceStatusDTO> {
        const response = await this.workspaceStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
