import React from 'react';

type ActionsMenuContentContext = {
  zIndex?: number;
};

export const ActionsMenuContentContext = React.createContext<ActionsMenuContentContext>({
  zIndex: undefined,
});

export const useActionsMenuContentContext = (): ActionsMenuContentContext => {
  return React.useContext(ActionsMenuContentContext);
};
