type CLDRPluralRules = Record<`_${number}`, string> & {
  one: string;
  other: string;
};

/**
 * CLDR Plural Rules
 *
 * CLDR defines 6 plural forms for grammatical number:
 * - zero: For languages with special handling of zero quantities
 * - one: Singular form (e.g. "1 book")
 * - two: Dual form for exactly two items
 * - few: Paucal form for small quantities
 * - many: Large quantities and often fractions
 * - other: Required catchall form, used as general plural or for single-form languages
 *
 * English Plural Rules:
 * | Count | Form  | Example     |
 * |-------|-------|-------------|
 * | 0     | other | "0 books"   |
 * | 1     | one   | "1 book"    |
 * | n     | other | "2+ books"  |
 *
 * @see {@link https://cldr.unicode.org/index/cldr-spec/plural-rules CLDR Plurals Documentation}
 * @see {@link https://unicode.org/cldr/charts/46/supplemental/language_plural_rules.html Language Plural Rules}
 */
export function plural(count: number, { one, other, ...rest }: CLDRPluralRules) {
  const key = `_${count}` as `_${number}`;

  if (rest.hasOwnProperty(key)) {
    return rest[key];
  }

  return count === 1 ? one : other;
}
