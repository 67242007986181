/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { WidgetAttributeDTO } from './WidgetAttributeDTO';
import {
    WidgetAttributeDTOFromJSON,
    WidgetAttributeDTOFromJSONTyped,
    WidgetAttributeDTOToJSON,
} from './WidgetAttributeDTO';

/**
 * 
 * @export
 * @interface WidgetDTO
 */
export interface WidgetDTO {
    /**
     * 
     * @type {Array<string>}
     * @memberof WidgetDTO
     */
    attributeNameMustMatchRegexes?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof WidgetDTO
     */
    attributeNameMustNotMatchRegexes?: Array<string>;
    /**
     * 
     * @type {Array<WidgetAttributeDTO>}
     * @memberof WidgetDTO
     */
    attributes?: Array<WidgetAttributeDTO>;
    /**
     * 
     * @type {Array<string>}
     * @memberof WidgetDTO
     */
    experimentShortIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof WidgetDTO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof WidgetDTO
     */
    name?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof WidgetDTO
     */
    namespaces?: Array<string>;
    /**
     * 
     * @type {object}
     * @memberof WidgetDTO
     */
    options?: object;
    /**
     * 
     * @type {string}
     * @memberof WidgetDTO
     */
    type: WidgetDTOTypeEnum;
}


/**
 * @export
 */
export const WidgetDTOTypeEnum = {
    Chart: 'chart',
    ValueList: 'valueList',
    File: 'file',
    FileSet: 'fileSet',
    Image: 'image',
    ImageComparison: 'imageComparison',
    InteractiveTable: 'interactiveTable',
    Gallery: 'gallery',
    Notebook: 'notebook',
    ScatterPlot: 'scatterPlot',
    SingleValue: 'singleValue',
    Table: 'table',
    TextNode: 'textNode',
    Section: 'section'
} as const;
export type WidgetDTOTypeEnum = typeof WidgetDTOTypeEnum[keyof typeof WidgetDTOTypeEnum];


/**
 * Check if a given object implements the WidgetDTO interface.
 */
export function instanceOfWidgetDTO(value: object): value is WidgetDTO {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    return true;
}

export function WidgetDTOFromJSON(json: any): WidgetDTO {
    return WidgetDTOFromJSONTyped(json, false);
}

export function WidgetDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): WidgetDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'attributeNameMustMatchRegexes': json['attributeNameMustMatchRegexes'] == null ? undefined : json['attributeNameMustMatchRegexes'],
        'attributeNameMustNotMatchRegexes': json['attributeNameMustNotMatchRegexes'] == null ? undefined : json['attributeNameMustNotMatchRegexes'],
        'attributes': json['attributes'] == null ? undefined : ((json['attributes'] as Array<any>).map(WidgetAttributeDTOFromJSON)),
        'experimentShortIds': json['experimentShortIds'] == null ? undefined : json['experimentShortIds'],
        'id': json['id'],
        'name': json['name'] == null ? undefined : json['name'],
        'namespaces': json['namespaces'] == null ? undefined : json['namespaces'],
        'options': json['options'] == null ? undefined : json['options'],
        'type': json['type'],
    };
}

export function WidgetDTOToJSON(value?: WidgetDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'attributeNameMustMatchRegexes': value['attributeNameMustMatchRegexes'],
        'attributeNameMustNotMatchRegexes': value['attributeNameMustNotMatchRegexes'],
        'attributes': value['attributes'] == null ? undefined : ((value['attributes'] as Array<any>).map(WidgetAttributeDTOToJSON)),
        'experimentShortIds': value['experimentShortIds'],
        'id': value['id'],
        'name': value['name'],
        'namespaces': value['namespaces'],
        'options': value['options'],
        'type': value['type'],
    };
}

