// Libs
import React, { HTMLAttributes, MouseEventHandler } from 'react';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';

import { joinClassNames } from '../../modules/bem';
import * as text from '../../modules/text';
import { clickableClassName } from '../../modules/ui-state';
import { createComponent, WithNonInterferingComponent } from '../../modules/utils';

// Module
export type IconStackProps<P extends object> = WithNonInterferingComponent<P, IconStackOwnProps> &
  HTMLAttributes<HTMLSpanElement> & {
    color?: text.Color;
    onClick?: MouseEventHandler;
    size?: SizeProp;
  };

type IconStackOwnProps = {
  className: string;
};

/**
 * You can create compound icons by layering two or more of them.
 * To do so, use `Icon.Stack` as container and pass `Icon` components as children.
 *
 * When layering icons, `transform` property of `Icon` may be very useful.
 *
 * ## Import
 *
 * ```
 * import { Icon } from '@neptune/shared/venus-ui';
 * ```
 *
 * And use `<Icon.Stack />`
 */
export function IconStack<P extends object>({
  children,
  className,
  color,
  component = 'span',
  size,
  ...props
}: IconStackProps<P>): React.ReactElement {
  const ownProps = {
    className: joinClassNames(
      'fa-layers',
      'fa-fw',
      'v-icon',
      size && `fa-${size}`,
      clickableClassName(props.onClick),
      text.colorClassName(color),
      className,
    ),
  };

  return createComponent(component, { ...ownProps, ...props }, children);
}
